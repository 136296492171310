
import { Injectable } from '@angular/core';
import { io } from 'socket.io-client';

import { DataService } from './data.service';
import { LoggerService } from './logger.service';

import { environment } from '../../../../environments/environment';


@Injectable({
  providedIn: 'root'
})


export class SocketService {

  constructor(private dataService: DataService, private logger: LoggerService) {
  }

  async create() {
    try {

      const service = 'user';

      const socket = io(environment.ws.url, {
        path: `/${service}/socket/`,
        transports: ['websocket'],
        auth: {
          authJwt: this.dataService.getProperty('authSession').authJwt
        }
      });

      this.dataService.setProfileSocket(socket);

      return;

    } catch (error) {
      this.logger.log('SocketService', 'create', error, 'error');
      return;
    }
  }

  async checkListeners(){
    try {

      if (!this.dataService.profileSocket) {
        return;
      }

      const listeners = this.dataService.profileSocket._callbacks;

      return listeners;

    } catch (error) {
      this.logger.log('SocketService', 'checkListeners', error, 'error');
      return;
    }
  }

  async connect() {
    try {

      await this.dataService.profileSocket.connect();

      return;

    } catch (error) {
      this.logger.log('SocketService', 'connect', error, 'error');
      return;
    }
  }

  async disconnect() {
    try {

      if (!this.dataService.profileSocket) {
        return;
      }

      await this.dataService.profileSocket.disconnect();

      return;

    } catch (error) {
      this.logger.log('SocketService', 'disconnect', error, 'error');
      return;
    }
  }

  async emit(event: string, data?: any) {
    try {

      if (!this.dataService.profileSocket) {
        return;
      }

      await this.dataService.profileSocket.emit(event, data);

      return;

    } catch (error) {
      this.logger.log('SocketService', 'emit', error, 'error');
      return;
    }
  }

  addListener(event: string, cb: any) {
    try {

      this.dataService.profileSocket.on(event, cb);

      return;

    } catch (error) {
      this.logger.log('SocketService', 'addListener', error, 'error');
      return;
    }
  }

  addOnceListener(event: string, cb: any) {
    try {

      this.dataService.profileSocket.once(event, cb);

      return;

    } catch (error) {
      this.logger.log('SocketService', 'addOnceListener', error, 'error');
      return;
      }
  }

  async removeListener(event: string) {
    try {

      await this.dataService.profileSocket.off(event);

      return;

    } catch (error) {
      this.logger.log('SocketService', 'off', error, 'error');
      return;
    }
  }

  async removeAllListeners() {
    try {

      await this.dataService.profileSocket.removeAllListeners();

      return;

    } catch (error) {
      this.logger.log('SocketService', 'removeAllListeners', error, 'error');
      return;
    }
  }

}
