
import { Injectable } from '@angular/core';
import { Router, CanActivate} from "@angular/router";

import { AuthService } from '../auth.service';
import { LoggerService } from './logger.service';
import { JwtService } from './jwt.service';
import { DataService } from './data.service';
import { SocketService } from './socket.service';


@Injectable({ providedIn: 'root' })


export class AuthGuard implements CanActivate {

  constructor(private jwtService: JwtService, private router: Router, private dataService: DataService, private socketService: SocketService, private authService: AuthService, private logger: LoggerService) {}

  async canActivate(): Promise<boolean> {
    try {

      var valid = true;

      const authSession = this.dataService.getProperty('authSession');

      if (!authSession) {
        valid = false;
      }

      if (valid) {
        
        const expired = this.jwtService.expired(this.dataService.getProperty('authSession').authJwt);

        if (expired) {
          valid = false;
        }
      }

      if (!valid) {
        this.socketService.emit('user_profile_session_ended');

        this.socketService.disconnect();

        this.dataService.deleteProfileSocket();
        this.dataService.deleteProperties();

        this.router.navigate(['/login'], { replaceUrl: true });

        return false;
      }

      return true;

    } catch (error) {
      this.logger.log('AuthGuard', 'canActivate', error, 'error');
      return;
    }
  }

}
