
import { Injectable } from '@angular/core';
import { JwtHelperService } from "@auth0/angular-jwt";

import { LoggerService } from './logger.service';


@Injectable({ providedIn: 'root' })


export class JwtService {

  jwtHelper: JwtHelperService = new JwtHelperService;

  constructor(private logger: LoggerService) {}

  expired(jwt: string): boolean {
    try {

      const expired = this.jwtHelper.isTokenExpired(jwt);

      return expired;

    } catch (error) {
      this.logger.log('JwtService', 'expired', error, 'error');
      return;
    }
  }
}
