
export class Account {

  constructor(private dataService: any, private helperService: any) {}

    biography_updated = async (data: any) => {
      try {

        //console.log('admin_biography_updated', data);
      
        const { biography } = data;
      
        var accounts = this.dataService.getProperty('accounts');
      
        for (var i = 0; i < accounts.length; i++) {
          if (accounts[i]._id === biography.accountId) {
            accounts[i].biography = biography;
            break;
          }
        }
      
        this.dataService.setProperty('accounts', accounts);

        if (this.dataService.getProperty('selectedAccount') && this.dataService.getProperty('selectedAccount')._id === biography.accountId) {
          var selectedAccount = this.dataService.getProperty('selectedAccount');
          selectedAccount.biography = biography;
          this.dataService.setProperty('selectedAccount', selectedAccount);
        }
      
        return;
      
      } catch (error) {
        console.log(error);
        return;
      }
    }

    retrieved = async (data: any) => {
        try {
      
          //console.log('admin_accounts_retrieved', data);
      
          var { accounts } = data;

          for (var i = 0; i < accounts.length; i++) {

            if (accounts[i].lastLogin) {
              accounts[i].lastLogin = new Date(accounts[i].lastLogin).toLocaleString();
            }

            accounts[i].createdAt = new Date(accounts[i].createdAt).toLocaleString();

            for (var j = 0; j < accounts[i].sessions.length; j++) {
              accounts[i].sessions[j].start = new Date(accounts[i].sessions[j].start).toLocaleString();
              accounts[i].sessions[j].end = new Date(accounts[i].sessions[j].end).toLocaleString();
            }
          }
      
          this.dataService.setProperty('accounts', accounts);
      
          return;
      
        } catch (error) {
          console.log(error);
          return;
        }
      }
      
      deleted = async (data: any) => {
        try {
      
          //console.log('admin_account_deleted', data);
      
          const { accountId } = data;
      
          var accounts = this.dataService.getProperty('accounts');
      
          for (var i = 0; i < accounts.length; i++) {
            if (accounts[i]._id === accountId) {
                accounts.splice(i, 1);
              break;
            }
          }
      
          this.dataService.setProperty('accounts', accounts);

          if (this.dataService.getProperty('selectedAccount') && this.dataService.getProperty('selectedAccount')._id === accountId) {
            this.dataService.deleteProperty('selectedAccount');
          }
      
          return;
      
        } catch (error) {
          console.log(error);
          return;
        }
      }
      
      updated = async (data: any) => {
        try {
      
          console.log('admin_account_updated', data);
      
          const { account } = data;
          const accountId = account._id;
      
          var accounts = this.dataService.getProperty('accounts');
      
          for (var i = 0; i < accounts.length; i++) {
            if (accounts[i]._id === accountId) {
              accounts[i] = account;
              break;
            }
          }
      
          this.dataService.setProperty('accounts', accounts);

          if (this.dataService.getProperty('selectedAccount') && this.dataService.getProperty('selectedAccount')._id === accountId) {
            console.log('selectedAccount found', account);
            this.dataService.setProperty('selectedAccount', account);
          }
      
          return;
      
        } catch (error) {
          console.log(error);
          return;
        }
      }
      
      created = async (data: any) => {
        try {
      
          //console.log('admin_account_created', data);
      
          const { account } = data;
      
          var accounts = this.dataService.getProperty('accounts');

          accounts.push(account);

          this.dataService.setProperty('accounts', accounts);
      
          return;
      
        } catch (error) {
          console.log(error);
          return;
        }
      }

      disabled = async (data: any) => {
        try {
      
          //console.log('admin_account_disabled', data);
      
          const { accountId } = data;
      
          var accounts = this.dataService.getProperty('accounts');
      
          for (var i = 0; i < accounts.length; i++) {
            if (accounts[i]._id === accountId) {
              accounts[i].active = false;
              break;
            }
          }
      
          this.dataService.setProperty('accounts', accounts);

          if (this.dataService.getProperty('selectedAccount') && this.dataService.getProperty('selectedAccount')._id === accountId) {
            var selectedAccount = this.dataService.getProperty('selectedAccount');
            selectedAccount.active = false;
            this.dataService.setProperty('selectedAccount', selectedAccount);
          }
      
          return;
      
        } catch (error) {
          console.log(error);
          return;
        }
      }

      enabled = async (data: any) => {
        try {
      
          //console.log('admin_account_enabled', data);
      
          const { accountId } = data;
      
          var accounts = this.dataService.getProperty('accounts');
      
          for (var i = 0; i < accounts.length; i++) {
            if (accounts[i]._id === accountId) {
              accounts[i].active = true;
              break;
            }
          }
      
          this.dataService.setProperty('accounts', accounts);

          if (this.dataService.getProperty('selectedAccount') && this.dataService.getProperty('selectedAccount')._id === accountId) {
            var selectedAccount = this.dataService.getProperty('selectedAccount');
            selectedAccount.active = true;
            this.dataService.setProperty('selectedAccount', selectedAccount);
          }
      
          return;
      
        } catch (error) {
          console.log(error);
          return;
        }
      }

}