
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../auth.service';
import { DataService } from './data.service';
import { FileService } from './file.service';
import { HelperService } from './helper.service';
import { LoggerService } from './logger.service';
import { SocketService } from './socket.service';

import { listeners } from './listeners/listeners';


@Injectable({ providedIn: 'root' })


export class ListenerService {

  constructor(private authService: AuthService, private listeners: listeners, private helperService: HelperService, private dataService: DataService, private fileService: FileService, private logger: LoggerService, private router: Router, private socketService: SocketService) {}

  user_account_profile_required = async () => {
    try {

      console.log('user_account_profile_required');

      await this.helperService.hideLoading();

      this.router.navigate(['/onboarding'], { replaceUrl: true });

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  user_profile_zipcode_retrieved = async (data: any) => {
    try {

      //console.log('user_profile_zipcode_retrieved', data);

      const { locality } = data;

      this.dataService.setProperty('locality', locality);

      await this.helperService.hideLoading();

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  user_profile_query_offline = async (data: any) => {
    try {

      //console.log('user_profile_query_offline', data);

      const { profileId } = data;

      var queries = this.dataService.getProperty('queries');

      if (!queries) {
        return;
      }

      var profiles = queries.profiles;
      var onlineProfiles = profiles.online;
      var offlineProfiles = profiles.offline;

      for (let i = 0; i < onlineProfiles.length; i++) {

        if (onlineProfiles[i].profileId === profileId) {

          onlineProfiles[i].online = false;
          const profile = onlineProfiles[i];

          offlineProfiles.unshift(profile);
          onlineProfiles.splice(i, 1);

          break;
        }
      }

      profiles.online = onlineProfiles;
      profiles.offline = offlineProfiles;

      queries.profiles = profiles;

      var flirts = queries.flirts;

      for (let i = 0; i < flirts.length; i++) {
        if (flirts[i].profileId === profileId) {
          flirts[i].online = false;
          break;
        }
      }

      queries.flirts = flirts;

      var views = queries.views;

      for (let i = 0; i < views.length; i++) {
        if (views[i].profileId === profileId) {
          views[i].online = false;
          break;
        }
      }

      queries.views = views;

      this.dataService.setProperty('queries', queries);

      this.dataService.toggleInterestsChanged();

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  user_profile_query_online = async (data: any) => {
    try {

      //console.log('user_profile_query_online', data);

      const { profileId } = data;

      var queries = this.dataService.getProperty('queries');

      if (!queries) {
        return;
      }

      var profiles = queries.profiles;
      var onlineProfiles = profiles.online;
      var offlineProfiles = profiles.offline;

      for (var i = 0; i < offlineProfiles.length; i++) {

        if (offlineProfiles[i].profileId === profileId) {

          offlineProfiles[i].online = true;
          const profile = offlineProfiles[i];

          onlineProfiles.unshift(profile);
          offlineProfiles.splice(i, 1);

          break;
        }
      }

      profiles.online = onlineProfiles;
      profiles.offline = offlineProfiles;

      queries.profiles = profiles;

      var flirts = queries.flirts;

      for (let i = 0; i < flirts.length; i++) {
        if (flirts[i].profileId === profileId) {
          flirts[i].online = true;
          break;
        }
      }

      queries.flirts = flirts;

      var views = queries.views;

      for (let i = 0; i < views.length; i++) {
        if (views[i].profileId === profileId) {
          views[i].online = true;
          break;
        }
      }

      queries.views = views;

      this.dataService.setProperty('queries', queries);

      this.dataService.toggleInterestsChanged();

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  user_profile_connection_offline = async (data: any) => {
    try {

      //console.log('user_profile_connection_offline', data);

      const { profileId } = data;

      var connections = this.dataService.getProperty('connections');

      for (var i = 0; i < connections.length; i++) {
        if (connections[i]._id === profileId) {

          connections[i].online = false;

          var posts = connections[i].posts;

          for (var j = 0; j < posts.length; j++) {
            posts[j].online = false;
          }

          connections[i].posts = posts;

          break;
        }
      }

      this.dataService.setProperty('connections', connections);

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  user_profile_connection_online = async (data: any) => {
    try {

      //console.log('user_profile_connection_online', data);

      const { profileId } = data;

      var connections = this.dataService.getProperty('connections');

      for (var i = 0; i < connections.length; i++) {
        if (connections[i]._id === profileId) {

          connections[i].online = true;

          var posts = connections[i].posts;

          for (var j = 0; j < posts.length; j++) {
            posts[j].online = true;
          }

          connections[i].posts = posts;

          break;
        }
      }

      this.dataService.setProperty('connections', connections);

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }


  user_profile_blocked = async (data: any) => {
    try {

      //console.log('user_profile_blocked', data);

      const { blockedAccountId } = data;

      const selectedProfile = this.dataService.getProperty('selectedProfile');

      if (selectedProfile) {

        var found = false;

        const accounts = selectedProfile.accounts;

        for (let i = 0; i < accounts.length; i++) {
          if (accounts[i].account === blockedAccountId) {
            found = true;
            break;
          }
        }

        if (found) {
          this.dataService.deleteProperty('selectedProfile');
          this.dataService.deleteProperty('selectedProfilePosts');
          this.helperService.goBack();
        }
      }

      const tagProfile = this.dataService.getProperty('tagProfile');

      if (tagProfile) {

        var found = false;

        const accounts = tagProfile.accounts;

        for (let i = 0; i < accounts.length; i++) {
          if (accounts[i].account === blockedAccountId) {
            found = true;
            break;
          }
        }

        if (found) {
          this.dataService.deleteProperty('tagProfile');
          this.helperService.goBack();
        }
      }

      const conversations = this.dataService.getProperty('conversations');

      if (conversations) {

        for (let i = 0; i < conversations.length; i++) {

          found = false;
  
          const members = conversations[i].members;
  
          for (let j = 0; j < members.length; j++) {
            if (members[j].account === blockedAccountId) {
              found = true;
              break;
            }
          }
  
          if (found) {
            conversations.splice(i, 1);
            i--;
          }
        }
  
        this.dataService.setProperty('conversations', conversations);
      }

      const selectedConversation = this.dataService.getProperty('selectedConversation');

      if (selectedConversation) {

        found = false;

        const members = selectedConversation.members;

        for (let i = 0; i < members.length; i++) {
          if (members[i].account === blockedAccountId) {
            found = true;
            break;
          }
        }

        if (found) {
          this.dataService.deleteProperty('selectedConversation');
          this.helperService.goBack();
        }
      }

      const connections = this.dataService.getProperty('connections');

      for (let i = 0; i < connections.length; i++) {

        var found = false;

        for (let j = 0; j < connections[i].accounts.length; j++) {
          if (connections[i].accounts[j].account === blockedAccountId) {
            found = true;
          }
        }

        if (found) {
          connections.splice(i, 1);
          i--;
        }
      }

      this.dataService.setProperty('connections', connections);

      const queries = this.dataService.getProperty('queries');

      if (queries) {

        for (let i = 0; i < queries.length; i++) {
        
          var found = false;
  
          for (let j = 0; j < queries[i].accounts.length; j++) {
            if (queries[i].accounts[j].account === blockedAccountId) {
              found = true;
            }
          }
  
          if (found) {
            queries.splice(i, 1);
            i--;
          }
        }
  
        this.dataService.setProperty('queries', queries);
      }

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  admin_accounts_retrieved = async (data: any) => {
    try {

      //console.log('admin_accounts_retrieved', data);

      const { accounts } = data;

      this.dataService.setProperty('accounts', accounts);

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  admin_account_deleted = async (data: any) => {
    try {

      //console.log('admin_account_deleted', data);

      const { account } = data;
      const accountId = account._id;

      const accounts = this.dataService.getProperty('accounts');

      for (var i = 0; i < accounts.length; i++) {
        if (accounts[i]._id === accountId) {
          accounts.splice(i, 1);
          break;
        }
      }

      this.dataService.setProperty('accounts', accounts);

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  admin_account_updated = async (data: any) => {
    try {

      //console.log('admin_account_updated', data);

      const { account } = data;

      var accounts = this.dataService.getProperty('accounts');

      for (var i = 0; i < accounts.length; i++) {
        if (accounts[i]._id === account._id) {
          accounts[i] = account;
          break;
        }
      }

      this.dataService.setProperty('accounts', accounts);

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  admin_account_created = async (data: any) => {
    try {

      //console.log('admin_account_created', data);

      const { account } = data;

      const accounts = this.dataService.getProperty('accounts');
      accounts.unshift(account);
      this.dataService.setProperty('accounts', accounts);

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  ////////////////////////////////////////////////////////////////////////////////////////////

  admin_account_verifications_retrieved = async (data: any) => {
    try {

      //console.log('admin_account_verifications_retrieved', data);

      const { verifications } = data;

      this.dataService.setProperty('verifications', verifications);

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  admin_account_verification_deleted = async (data: any) => {
    try {

      //console.log('admin_account_verification_deleted', data);

      const { verification } = data;

      const verificationId = verification._id;

      const verifications = this.dataService.getProperty('verifications');

      for (var i = 0; i < verifications.pending.length; i++) {
        if (verifications.pending[i]._id === verificationId) {
          verifications.pending.splice(i, 1);
          break;
        }
      }

      for (var i = 0; i < verifications.verified.length; i++) {
        if (verifications.verified[i]._id === verificationId) {
          verifications.verified.splice(i, 1);
          break;
        }
      }

      for (var i = 0; i < verifications.denied.length; i++) {
        if (verifications.denied[i]._id === verificationId) {
          verifications.denied.splice(i, 1);
          break;
        }
      }

      this.dataService.setProperty('verifications', verifications);

      var accounts = this.dataService.getProperty('accounts');

      for (var i = 0; i < accounts.length; i++) {
        if (accounts[i]._id === verification.accountId) {
          accounts[i].verification = null;
          break;
        }
      }

      this.dataService.setProperty('accounts', accounts);

      if (this.dataService.getProperty('selectedAccount') && this.dataService.getProperty('selectedAccount')._id === verification.accountId) {
        var selectedAccount = this.dataService.getProperty('selectedAccount');
        selectedAccount.verification = null;
        this.dataService.setProperty('selectedAccount', selectedAccount);
      }

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  admin_account_verification_created = async (data: any) => {
    try {

      //console.log('admin_account_verification_created', data);

      const { verification } = data;

      const verifications = this.dataService.getProperty('verifications');
      verifications.pending.unshift(verification);
      this.dataService.setProperty('verifications', verifications);

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  admin_account_verification_processed = async (data: any) => {
    try {

      //console.log('admin_account_verification_processed', data);

      const { verification } = data;
      const verificationId = verification._id;

      const verifications = this.dataService.getProperty('verifications');

      for (var i = 0; i < verifications.pending.length; i++) {
        if (verifications.pending[i]._id === verificationId) {
          verifications.pending.splice(i, 1);
          break;
        }
      }

      const status = verification.status;

      if (status === 'Verified') {
        verifications.verified.unshift(verification);
      }

      if (status === 'Denied') {
        verifications.denied.unshift(verification);
      }

      this.dataService.setProperty('verifications', verifications);

      var accounts = this.dataService.getProperty('accounts');

      for (var i = 0; i < accounts.length; i++) {
        if (accounts[i]._id === verification.accountId) {
          accounts[i].verification = verification;
          break;
        }
      }

      this.dataService.setProperty('accounts', accounts);

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  ////////////////////////////////////////////////////////////////////////////////////////////

  user_profile_invite_sent = async (data: any) => {
    try {

      this.dataService.setProperty('sendInvite', true);

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  user_system_settings_updated = async (data: any) => {
    try {

      console.log('user_system_settings_updated', data);

      const { settings } = data;

      this.dataService.setProperty('settings', settings);

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  user_system_settings_retrieved = async (data: any) => {
    try {

      console.log('user_system_settings_retrieved', data);

      const { settings } = data;

      this.dataService.setProperty('settings', settings);

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  user_account_verification_deleted = async () => {
    try {

      //console.log('user_account_verification_deleted');

      this.dataService.deleteProperty('verification');

      const verificationFiles = {
        id_front: null,
        id_back: null,
        face: null,
      };
  
      this.dataService.setProperty('verificationFiles', verificationFiles);

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  user_account_verification_updated = async (data: any) => {
    try {

      //console.log('user_account_verification_updated', data);

      const { verification } = data;
      const status = verification.status;

      this.dataService.setProperty('verification', verification);

      if (status === 'Verified') {
        this.dataService.deleteProperty('verificationFiles');
      }

      if (status === 'Denied') {

        const verificationFiles = {
          id_front: null,
          id_back: null,
          face: null,
        };
    
        this.dataService.setProperty('verificationFiles', verificationFiles);
      }

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  user_account_verification_retrieved = async (data: any) => {
    try {

      //console.log('user_account_verification_retrieved', data);

      var { verification } = data;

      this.dataService.setProperty('verification', verification);
      this.dataService.deleteProperty('verificationFiles');

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  user_profile_tag_check_retrieved = async (data: any) => {
    try {

      //console.log('user_profile_tag_check_retrieved', data);

      const { exists } = data;
      const { type, tag, zipcode, responses, displayName, joinCode } = this.dataService.getProperty('profileData') || {};

      await this.helperService.hideLoading();

      if (exists !== null && exists !== undefined) {

        this.dataService.setProperty('tagExists', exists);

        if (!exists) {
          this.dataService.setProperty('nextDisabled', false);
        }

        return;
      }

      if (!exists) {

        var profileData: any = {
          type: type,
          tag: tag,
          zipcode: zipcode,
          responses: responses,
          displayName: displayName
        };

        if (type === 'Shared') {
          profileData.joinCode = joinCode;
        }

        this.socketService.emit('user_profile_requested', profileData);

        this.dataService.deleteProperty('profileData');
        this.router.navigate(['/tabs/profile/manage']);

      }

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  user_profile_query_viewed = async (data: any) => {
    try {

      //console.log('user_profile_query_viewed', data);

      var { view } = data;

      var flirted = false;

      const profileId = view.profileId;

      var queries = this.dataService.getProperty('queries');

      if (!queries) {
        return;
      }

      var views = queries.views;

      for (var i = 0; i < views.length; i++) {
        if (views[i].profileId === profileId) {
          flirted = views[i].flirted;
          views.splice(i, 1);
        }
      }

      const type = view.type;
      const accounts = view.accounts;
      const display = this.helperService.processTypeDisplay(accounts, type);

      view.display = display;
      view.flirted = flirted;

      views.unshift(view);

      queries.views = views;

      this.dataService.setProperty('queries', queries);

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  user_profile_query_post_updated = async (data: any) => {
    try {

      //console.log('user_profile_query_post_updated', data);

      const { post } = data;

      var queries = this.dataService.getProperty('queries');

      for (var i = 0; i < queries.posts.length; i++) {
        if (queries.posts[i]._id === post._id) {
          queries.posts[i] = post;
          break;
        }
      }

      this.dataService.setProperty('queries', queries);

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  user_profile_query_post_unpublished = async (data: any) => {
    try {

      //console.log('user_profile_query_post_unpublished', data);

      const { postId } = data;

      var queries = this.dataService.getProperty('queries');

      for (var i = 0; i < queries.posts.length; i++) {
        if (queries.posts[i]._id === postId) {
          queries.posts.splice(i, 1);
          break;
        }
      }

      this.dataService.setProperty('queries', queries);

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  user_profile_query_post_published = async (data: any) => {
    try {

      //console.log('user_profile_query_post_published', data);

      const { post } = data;

      const queries = this.dataService.getProperty('queries');

      const posts = queries.posts;
      posts.unshift(post);

      this.dataService.setProperty('queries', queries);

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  user_profile_query_blocked = async (data: any) => {
    try {

      //console.log('user_profile_query_blocked', data);

      const { queryId } = data;

      var queries = this.dataService.getProperty('queries');

      for (var i = 0; i < queries.length; i++) {

        if (queries[i].queryId === queryId) {

          queries.splice(i, 1);

          break;
        }
      }

      this.dataService.setProperty('queries', queries);

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  user_profile_flirt_received = async (data: any) => {
    try {

      //console.log('user_profile_flirt_received', data);

      var { flirt } = data;

      const type = flirt.type;
      const accounts = flirt.accounts;
      const display = this.helperService.processTypeDisplay(accounts, type);
      flirt.display = display;

      const queries = this.dataService.getProperty('queries');

      if (queries.flirts.length > 0) {
        queries.flirts.unshift(flirt);
      }

      if (queries.flirts.length === 0) {
        queries.flirts.push(flirt);
      }
      
      this.dataService.setProperty('queries', queries);

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  user_profile_query_flirted = async (data: any) => {
    try {

      //console.log('user_profile_query_flirted', data);

      const { queryId } = data;

      var queries = this.dataService.getProperty('queries');
      var profiles = queries.profiles;
      var onlineProfiles = profiles.online;
      var offlineProfiles = profiles.offline;
      var flirts = queries.flirts;
      var views = queries.views;

      var found = false;

      for (var i = 0; i < onlineProfiles.length; i++) {
        if (onlineProfiles[i].queryId === queryId) {
          onlineProfiles[i].flirted = true;
          found = true;
          break;
        }
      }

      if (!found) {
        for (var i = 0; i < offlineProfiles.length; i++) {
          if (offlineProfiles[i].queryId === queryId) {
            offlineProfiles[i].flirted = true;
            break;
          }
        }
      }

      for (var i = 0; i < flirts.length; i++) {
        if (flirts[i].queryId === queryId) {
          flirts[i].flirted = true;
          break;
        }
      }

      for (var i = 0; i < views.length; i++) {
        if (views[i].queryId === queryId) {
          views[i].flirted = true;
          break;
        }
      }

      queries.profiles = {
        online: onlineProfiles,
        offline: offlineProfiles
      };

      queries.flirts = flirts;
      queries.views = views;

      this.dataService.setProperty('queries', queries);

      var selectedProfile = this.dataService.getProperty('selectedProfile');

      if (selectedProfile && selectedProfile.queryId === queryId) {
        selectedProfile.flirted = true;
        this.dataService.setProperty('selectedProfile', selectedProfile);
      }

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  query_file_unpublished = async (data: any) => {
    try {

      const { queryId, index } = data;

      var queries = this.dataService.getProperty('queries');

      for (var i = 0; i < queries.length; i++) {

        if (queries[i].queryId == queryId) {

          queries[i].files.splice(index, 1);

          break;
        }
      }

      this.dataService.setProperty('queries', queries);

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  query_file_published = async (data: any) => {
    try {

      const { queryId, index, handle } = data;

      var queries = this.dataService.getProperty('queries');

      for (var i = 0; i < queries.length; i++) {

        if (queries[i].queryId == queryId) {

          queries[i].files.splice(index, 0, handle);

          break;
        }
      }

      this.dataService.setProperty('queries', queries);

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  user_profile_query_file_unpublished = async (data: any) => {
    try {

      const { profileId, index } = data;

      var accountProfiles = this.dataService.getProperty('accountProfiles');

      for (var i = 0; i < accountProfiles.length; i++) {

        if (accountProfiles[i]._id == profileId) {

          accountProfiles[i].query.files[index] = null;

          break;
        }
      }

      this.dataService.setProperty('accountProfiles', accountProfiles);

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  user_profile_query_file_published = async (data: any) => {
    try {

      const { profileId, index, handle } = data;

      var accountProfiles = this.dataService.getProperty('accountProfiles');

      for (var i = 0; i < accountProfiles.length; i++) {

        if (accountProfiles[i]._id == profileId) {

          accountProfiles[i].query.files[index] = handle;

          break;
        }
      }

      this.dataService.setProperty('accountProfiles', accountProfiles);

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  user_profile_queries_retrieved = async (data: any) => {
    try {

      //console.log('user_profile_queries_retrieved', data);

      var { queries } = data;

      const filters = queries.filters;
      const onlineProfiles = [];
      const offlineProfiles = [];
      var flirts = [];
      var views = [];

      for (let i = 0; i < queries.profiles.length; i++) {

        const profile = queries.profiles[i];

        if (profile.online) {
          onlineProfiles.push(profile);
        } else {
          offlineProfiles.push(profile);
        }

        if (profile.flirtDate) {
          flirts.push(profile);
        }

        if (profile.viewDate) {
          views.push(profile);
        }
      }

      if (onlineProfiles) {
        for (var i = 0; i < onlineProfiles.length; i++) {
          const type = onlineProfiles[i].type;
          const accounts = onlineProfiles[i].accounts;
          const display = this.helperService.processTypeDisplay(accounts, type);
          onlineProfiles[i].display = display;
        }
      }

      if (offlineProfiles) {
        for (var i = 0; i < offlineProfiles.length; i++) {
          const type = offlineProfiles[i].type;
          const accounts = offlineProfiles[i].accounts;
          const display = this.helperService.processTypeDisplay(accounts, type);
          offlineProfiles[i].display = display;
        }
      }

      if (flirts) {
        for (var i = 0; i < flirts.length; i++) {
          const type = flirts[i].type;
          const accounts = flirts[i].accounts;
          const display = this.helperService.processTypeDisplay(accounts, type);
          flirts[i].display = display;
        }
      }

      if (views) {
        for (var i = 0; i < views.length; i++) {
          const type = views[i].type;
          const accounts = views[i].accounts;
          const display = this.helperService.processTypeDisplay(accounts, type);
          views[i].display = display;
        }

        views = views.reverse();
      }

      queries = {
        filters: filters,
        profiles: {
          online: onlineProfiles,
          offline: offlineProfiles
        },
        flirts: flirts,
        views: views
      };

      this.dataService.setProperty('queries', queries);

      this.dataService.toggleInterestsChanged();

      await this.helperService.hideLoading();

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  user_profile_query_updated = async (data: any) => {
    try {

      //console.log('user_profile_query_updated', data);

      var { query } = data

      var accountProfile = this.dataService.getProperty('accountProfile');

      const updateType = query.type;

      const type = query.profile.type;
      const accounts = query.profile.accounts;
      const display = this.helperService.processTypeDisplay(accounts, type);
      query.profile.display = display;

      accountProfile.query = query;

      this.dataService.setProperty('accountProfile', accountProfile);

      const active = query.active;
      
      if (active && updateType === 'filter') {
        this.socketService.emit('user_profile_queries_requested');
      }

      if (!active && updateType === 'filter') {

        var queries = this.dataService.getProperty('queries');

        const profiles = {
          online: [],
          offline: []
        };

        const flirts = [];
        const views = [];

        queries.filters.active = active;
        queries.profiles = profiles;
        queries.flirts = flirts;
        queries.views = views;

        this.dataService.setProperty('queries', queries);

        this.dataService.setProperty('filteredProfiles', profiles);

        await this.helperService.hideLoading();
      }

      if (updateType === 'interest') {

        var interests = this.dataService.getProperty('interests');

        interests.connections = query.interests.connections;
        interests.activities = query.interests.activities;
        interests.sexual = query.interests.sexual;

        this.dataService.setProperty('interests', interests);

        this.dataService.toggleInterestsChanged();

        await this.helperService.hideLoading();
      }

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  user_profile_query_published = async (data: any) => {
    try {

      var { query } = data;

      var accountProfile = this.dataService.getProperty('accountProfile');

      const type = query.profile.type;
      const accounts = query.profile.accounts;
      const display = this.helperService.processTypeDisplay(accounts, type);
      query.profile.display = display;

      accountProfile.query = query;

      this.dataService.setProperty('accountProfile', accountProfile);

      this.dataService.deleteProperty('queries');
      this.socketService.emit('user_profile_queries_requested');

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  user_profile_query_unpublished = async () => {
    try {

      var accountProfile = this.dataService.getProperty('accountProfile');

      delete accountProfile.query

      this.dataService.setProperty('accountProfile', accountProfile);
      this.dataService.deleteProperty('queries');

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  user_conversation_published = async (data: any) => {
    try {

      const { conversation } = data;

      var conversations = this.dataService.getProperty('conversations');

      conversations.push(conversation);

      this.dataService.setProperty('conversations', conversations);

      if (this.dataService.getProperty('pendingConversation')) {

        this.dataService.setProperty('selectedConversation', conversation);
        this.helperService.goTo('chat');
        this.dataService.deleteProperty('pendingConversation');

        await this.helperService.hideLoading();
      }

      return;

    } catch (error) {
      this.logger.log('ListenerService', 'user_conversation_published', error, 'error');
      return;
    }
  }

  user_conversation_unpublished = async (data: any) => {
    try {

      const { conversationId } = data;

      var conversations = this.dataService.getProperty('conversations');

      for (var i = 0; i < conversations.length; i++) {
        if (conversations[i]._id == conversationId) {
          conversations.splice(i, 1);
          break;
        }
      }

      this.dataService.setProperty('conversations', conversations);

      return;

    } catch (error) {
      this.logger.log('ListenerService', 'user_conversation_unpublished', error, 'error');
      return;
    }
  }

  user_conversation_message_published = async (data: any) => {
    try {

      //console.log('user_conversation_message_published', data);

      const { conversationId } = data;
      var { conversationMessage } = data;

      const messageAccountId = conversationMessage.key.split(':')[1];

      var conversations = this.dataService.getProperty('conversations');

      for (var i = 0; i < conversations.length; i++) {

        if (conversations[i]._id === conversationId) {

          for (var j = 0; j < conversations[i].members.length; j++) {

            if (conversations[i].members[j].account == messageAccountId) {

              for (var k = 0; k < conversations[i].members[j].profile.connection.accounts.length; k++) {

                if (conversations[i].members[j].profile.connection.accounts[k].account === messageAccountId) {
                  conversationMessage.displayName = conversations[i].members[j].profile.connection.accounts[k].displayName;
                  break;
                }
              }
            }
          }

          const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            weekday: 'long',
          };

          var date: any;
    
          if (conversations[i].messages.length === 0) {
            date = new Date();
          }

          if (conversations[i].messages.length > 0) {
            date = new Date(conversations[i].messages[0].date);
          }

          date = date.toLocaleDateString('en-US', options);

          var newDate: any = new Date(conversationMessage.date);
          newDate = newDate.toLocaleDateString('en-US', options);

          if (!date || newDate !== date) {

            const message = {
              header: newDate
            };

            conversations[i].messages.unshift(message);
          }

          conversations[i].messages.unshift(conversationMessage);

          break;
        }
      }

      this.dataService.setProperty('conversations', conversations);

      this.dataService.toggleMessagesChanged(conversationId);

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  user_conversation_message_unpublished = async (data: any) => {
    try {

      const { conversationId, messageId } = data;

      var conversations = this.dataService.getProperty('conversations');

      for (var i = 0; i < conversations.length; i++) {
        if (conversations[i]._id == conversationId) {
          for (var j = 0; j < conversations[i].messages.length; j++) {
            if (conversations[i].messages[j]._id == messageId) {
              conversations[i].messages.splice(j, 1);
              break;
            }
          }
          break;
        }
      }

      this.dataService.setProperty('conversations', conversations);

      return;

    } catch (error) {
      this.logger.log('ListenerService', 'user_conversation_message_unpublished', error, 'error');
      return;
    }
  }

  user_conversation_message_updated = async (data: any) => {
    try {

      const { conversationId, updatedConversationMessage } = data;

      var conversations = this.dataService.getProperty('conversations');

      for (var i = 0; i < conversations.length; i++) {
        if (conversations[i]._id == conversationId) {
          for (var j = 0; j < conversations[i].messages.length; j++) {
            if (conversations[i].messages[j]._id == updatedConversationMessage._id) {
              conversations[i].messages[j] = updatedConversationMessage;
              break;
            }
          }
          break;
        }
      }

      this.dataService.setProperty('conversations', conversations);

      return;

    } catch (error) {
      this.logger.log('ListenerService', 'user_conversation_message_updated', error, 'error');
      return;
    }
  }

  user_conversation_updated = async (data: any) => {
    try {

      const { conversationId, updatedConversation } = data;

      var conversations = this.dataService.getProperty('conversations');

      for (var i = 0; i < conversations.length; i++) {
        if (conversations[i]._id == conversationId) {
          conversations[i] = updatedConversation;
          break;
        }
      }

      this.dataService.setProperty('conversations', conversations);

      return;

    } catch (error) {
      this.logger.log('ListenerService', 'user_conversation_updated', error, 'error');
      return;
    }
  }

  user_conversation_profile_added = async (data: any) => {
    try {

      const { conversationId, conversationProfile } = data;

      var conversations = this.dataService.getProperty('conversations');

      for (var i = 0; i < conversations.length; i++) {
        if (conversations[i]._id == conversationId) {
          conversations[i].profiles.push(conversationProfile);
          break;
        }
      }

      this.dataService.setProperty('conversations', conversations);

      return;

    } catch (error) {
      this.logger.log('ListenerService', 'user_conversation_profile_added', error, 'error');
      return;
    }
  }

  user_conversation_profile_removed = async (data: any) => {
    try {

      const { conversationId, conversationProfileId } = data;

      var conversations = this.dataService.getProperty('conversations');

      for (var i = 0; i < conversations.length; i++) {
        if (conversations[i]._id == conversationId) {
          for (var j = 0; j < conversations[i].profiles.length; j++) {
            if (conversations[i].profiles[j]._id == conversationProfileId) {
              conversations[i].profiles.splice(j, 1);
              break;
            }
          }
          break;
        }
      }

      this.dataService.setProperty('conversations', conversations);

      return;

    } catch (error) {
      this.logger.log('ListenerService', 'user_conversation_profile_removed', error, 'error');
      return;
    }
  }

  user_conversations_retrieved = async (data: any) => {
    try {

      var { conversations } = data;

      const accountProfile = this.dataService.getProperty('accountProfile');

      for (var i = 0; i < conversations.length; i++) {

        const members = conversations[i].members;

        for (var j = 0; j < members.length; j++) {

          if (members[j].profile._id !== accountProfile._id) {

            const connections = this.dataService.getProperty('connections');

            for (var k = 0; k < connections.length; k++) {
              if (connections[k]._id === members[j].profile._id) {
                conversations[i].thumbnail = connections[k].files[0];
                break;
              }
            }

            break;
          }
        }

        var date: any;
        var dateType: string;
        var displayDate: any;

        const options = {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          weekday: 'long',
        };
    
        if (conversations[i].messages.length === 0) {
          date = new Date();
        }

        if (conversations[i].messages.length > 0) {
          date = new Date(conversations[i].messages[conversations[i].messages.length - 1].date);
        }

        const dateString = date.toLocaleDateString('en-US', options);
  
        var newDate: any = new Date();
        var newDateString = newDate.toLocaleDateString('en-US', options);

        if (newDateString === dateString) {
          displayDate = date;
          dateType = 'time';
        }
  
        if (newDateString !== dateString) {
          displayDate = date;
          dateType = 'day';
        }

        conversations[i].displayDate = displayDate;
        conversations[i].dateType = dateType;

        for (var j = 0; j < conversations[i].messages.length; j++) {

          newDate = new Date(conversations[i].messages[j].date);
          newDate = newDate.toLocaleDateString('en-US', options);

          if ((!date) || (date !== newDate)) {

            date = newDate;

            const message = {
              header: date
            };

            conversations[i].messages.splice(j, 0, message);

            j++;
          }

          if (conversations[i].messages[j].key) {

            const messageAccountId = conversations[i].messages[j].key.split(':')[1];

            for (var k = 0; k < conversations[i].members.length; k++) {

              if (conversations[i].members[k].account == messageAccountId) {

                for (var l = 0; l < conversations[i].members[k].profile.connection.accounts.length; l++) {

                  if (conversations[i].members[k].profile.connection.accounts[l].account == messageAccountId) {
                    conversations[i].messages[j].displayName = conversations[i].members[k].profile.connection.accounts[l].displayName;
                    break;
                  }
                }
              }
            }
          }
        }

        const reversedMessages = conversations[i].messages.reverse();
        conversations[i].messages = reversedMessages;
      } 

      this.dataService.setProperty('conversations', conversations);

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  ////////////////////////////////////////////////////////////////////////////////////////////

  user_ticket_updated = async (data: any) => {
    try {

      //console.log('user_ticket_updated', data);

      const { ticket } = data;

      var tickets = this.dataService.getProperty('tickets');

      for (var i = 0; i < tickets.length; i++) {

        if (tickets[i]._id === ticket._id) {

          tickets[i] = ticket;
          break;
        }
      }

      this.dataService.setProperty('tickets', tickets);

      if (this.dataService.getProperty('selectedTicket')) {
        if (this.dataService.getProperty('selectedTicket')._id === ticket._id) {
          this.dataService.setProperty('selectedTicket', ticket);
        }
      }

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  user_ticket_unpublished = async (data: any) => {
    try {

      //console.log('user_ticket_unpublished', data);

      const { ticketId } = data;

      var tickets = this.dataService.getProperty('tickets');

      for (var i = 0; i < tickets.length; i++) {

        if (tickets[i]._id === ticketId) {

          tickets.splice(i, 1);
          break;
        }
      }

      this.dataService.setProperty('tickets', tickets);

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  user_ticket_published = async (data: any) => {
    try {

      //console.log('user_ticket_published', data);

      const { ticket } = data;

      var tickets = this.dataService.getProperty('tickets');

      if (!tickets) {
        tickets = [];
      }

      tickets.push(ticket);

      this.dataService.setProperty('tickets', tickets);

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  user_tickets_retrieved = async (data: any) => {
    try {

      //console.log('user_tickets_retrieved', data);

      const { tickets } = data;

      this.dataService.setProperty('tickets', tickets);

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  ////////////////////////////////////////////////////////////////////////////////////////////

  user_social_connection_unpublished = async (data: any) => {
    try {

      //console.log('user_social_connection_unpublished', data);

      var { connectionId } = data;

      var connections = this.dataService.getProperty('connections');

      for (var i = 0; i < connections.length; i++) {

        if (connections[i]._id === connectionId) {

          connections.splice(i, 1);
          break;
        }
      }

      this.dataService.setProperty('connections', connections);

      var masterFeed = this.dataService.getProperty('masterFeed');

      for (var i = 0; i < masterFeed.length; i++) {
        if (masterFeed[i].profileId === connectionId) {
          masterFeed.splice(i, 1);
        }
      }

      this.dataService.setProperty('masterFeed', masterFeed);

      var displayFeed = this.dataService.getProperty('displayFeed');

      for (var i = 0; i < displayFeed.length; i++) {
        if (displayFeed[i].profileId === connectionId) {
          displayFeed.splice(i, 1);
        }
      }

      this.dataService.setProperty('displayFeed', displayFeed);

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  user_connection_invite_published = async (data: any) => {
    try {

      //console.log('user_connection_invite_published', data);

      var { invite } = data;

      var invites = this.dataService.getProperty('invites');

      invites.push(invite);

      this.dataService.setProperty('invites', invites);

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  user_profile_session_updated = async (data: any) => {
    try {

      //console.log('user_profile_session_updated', data);

      var { accountId, profileId } = data;

      var accountProfile: any;
      var profiles = this.dataService.getProperty('accountProfiles');

      for (var i = 0; i < profiles.length; i++) {

        if (profiles[i]._id === profileId) {
          accountProfile = profiles[i];
          break;
        }
      }

      for (var i = 0; i < accountProfile.posts.length; i++) {

        accountProfile.posts[i].profileId = accountProfile._id;
        accountProfile.posts[i].tag = accountProfile.tag;

        for (var j = 0; j < accountProfile.accounts.length; j++) {

          if (accountProfile.posts[i].account == accountProfile.accounts[j]._id) {
            accountProfile.posts[i].displayName = accountProfile.accounts[j].displayName;
          }
        }

      }

      this.dataService.deleteProperty('masterFeed');
      this.dataService.deleteProperty('displayFeed');

      this.fileService.timeBulkSort(accountProfile.posts);

      this.dataService.setProperty('accountProfile', accountProfile);
      this.dataService.setProperty('accountProfiles', profiles);

      this.dataService.deleteProperty('connections');
      this.dataService.deleteProperty('invites');
      this.dataService.deleteProperty('notifications');
      this.dataService.deleteProperty('queries');

      this.postRequestProfileSession();

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  user_profile_collection_unpublished = async (data: any) => {
    try {

      //console.log('user_profile_collection_unpublished', data);

      const { profileId, collectionId } = data;

      var selectedProfile = this.dataService.getProperty('selectedProfile');

      if (selectedProfile && selectedProfile._id === profileId) {

        var collections = selectedProfile.collections;

        for (var i = 0; i < collections.length; i++) {

          if (collections[i]._id === collectionId) {
            collections.splice(i, 1);
            break;
          }
        }

        this.dataService.setProperty('selectedProfile', selectedProfile);
      }

      var found = false;

      var accountProfiles = this.dataService.getProperty('accountProfiles');

      for (var i = 0; i < accountProfiles.length; i++) {

        if (accountProfiles[i]._id === profileId) {

          for (var j = 0; j < accountProfiles[i].collections.length; j++) {

            if (accountProfiles[i].collections[j]._id == collectionId) {

              accountProfiles[i].collections.splice(j, 1);

              found = true;
              break;
            }
          }
        }
      }

      this.dataService.setProperty('accountProfiles', accountProfiles);

      if (found) {
            
        var accountProfile = this.dataService.getProperty('accountProfile');
    
        if (profileId === accountProfile._id) {
    
          for (var j = 0; j < accountProfile.collections.length; j++) {
    
            if (accountProfile.collections[j]._id == collectionId) {
    
              accountProfile.collections.splice(j, 1);
              this.dataService.setProperty('accountProfile', accountProfile);
              break;
            }
          }
        }
      }

      if (!found) {

        var connections = this.dataService.getProperty('connections');

        for (var i = 0; i < connections.length; i++) {

          if (connections[i]._id == profileId) {

            for (var j = 0; j < connections[i].collections.length; j++) {

              if (connections[i].collections[j]._id == collectionId) {

                connections[i].collections.splice(j, 1);

                break;
              }
            }
          }
        }

        this.dataService.setProperty('connections', connections);
      }

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  user_profile_collection_updated = async (data: any) => {
    try {

      //console.log('user_profile_collection_updated', data);

      const { profileId, collection } = data;

      var selectedCollection = this.dataService.getProperty('selectedCollection');

      if (selectedCollection && selectedCollection._id === collection._id) {
        this.dataService.setProperty('selectedCollection', collection);
      }

      var selectedProfile = this.dataService.getProperty('selectedProfile');

      if (selectedProfile && selectedProfile._id === profileId) {

        var collections = selectedProfile.collections;

        for (var i = 0; i < collections.length; i++) {

          if (collections[i]._id === collection._id) {
            collections[i] = collection;
            break;
          }
        }

        this.dataService.setProperty('selectedProfile', selectedProfile);
      }

      var found = false;

      var accountProfiles = this.dataService.getProperty('accountProfiles');

      for (var i = 0; i < accountProfiles.length; i++) {

        if (accountProfiles[i]._id == profileId) {

          for (var j = 0; j < accountProfiles[i].collections.length; j++) {

            if (accountProfiles[i].collections[j]._id == collection._id) {

              accountProfiles[i].collections[j] = collection;

              found = true;
              break;
            }
          }
        }
      }

      this.dataService.setProperty('accountProfiles', accountProfiles);

      if (found) {
          
        var accountProfile = this.dataService.getProperty('accountProfile');
  
        if (profileId === accountProfile._id) {
  
          for (var j = 0; j < accountProfile.collections.length; j++) {
  
            if (accountProfile.collections[j]._id == collection._id) {
  
              accountProfile.collections[j] = collection;
              this.dataService.setProperty('accountProfile', accountProfile);
              break;
            }
          }
        }
      }

      if (!found) {

        var connections = this.dataService.getProperty('connections');

        for (var i = 0; i < connections.length; i++) {

          if (connections[i].profile._id == profileId) {

            for (var j = 0; j < connections[i].profile.collections.length; j++) {

              if (connections[i].profile.collections[j]._id == collection._id) {

                connections[i].profile.collections[j] = collection;

                break;
              }
            }
          }
        }

        this.dataService.setProperty('connections', connections);
      }

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  user_profile_collection_file_unpublished = async (data: any) => {
    try {

      //console.log('user_profile_collection_file_unpublished', data);

      const { profileId, collectionId, handle } = data;

      var selectedProfile = this.dataService.getProperty('selectedProfile');

      if (selectedProfile && selectedProfile._id === profileId) {

        for (var j = 0; j < selectedProfile.collections.length; j++) {

          if (collectionId == selectedProfile.collections[j]._id) {

            for (var k = 0; k < selectedProfile.collections[j].files.length; k++) {

              const file = selectedProfile.collections[j].files[k].split('?')[0].split('/')[3];

              if (handle == file) {

                selectedProfile.collections[j].files.splice(k, 1);
                this.dataService.setProperty('selectedProfile', selectedProfile);
                break;
              }
            }
          }
        }
      }

      var found = false;

      const accountProfiles = this.dataService.getProperty('accountProfiles');

      for (var i = 0; i < accountProfiles.length; i++) {

        if (profileId == accountProfiles[i]._id) {

          for (var j = 0; j < accountProfiles[i].collections.length; j++) {

            if (collectionId == accountProfiles[i].collections[j]._id) {

              for (var k = 0; k < accountProfiles[i].collections[j].files.length; k++) {

                const file = accountProfiles[i].collections[j].files[k].split('?')[0].split('/')[3];

                if (handle == file) {

                  accountProfiles[i].collections[j].files.splice(k, 1);

                  found = true;
                  break;
                }
              }
            }
          }

          this.dataService.setProperty('accountProfiles', accountProfiles);
        }
      }

      if (found) {
          
          const accountProfile = this.dataService.getProperty('accountProfile');
  
          if (profileId === accountProfile._id) {
  
            for (var j = 0; j < accountProfile.collections.length; j++) {
  
              if (collectionId == accountProfile.collections[j]._id) {
  
                for (var k = 0; k < accountProfile.collections[j].files.length; k++) {
  
                  const file = accountProfile.collections[j].files[k].split('?')[0].split('/')[3];
  
                  if (handle == file) {
  
                    accountProfile.collections[j].files.splice(k, 1);
                    this.dataService.setProperty('accountProfile', accountProfile);
                    break;
                  }
                }
              }
            }
          }
      }

      if (!found) {

        const connections = this.dataService.getProperty('connections');

        for (var i = 0; i < connections.length; i++) {

          if (profileId == connections[i].profile._id) {

            for (var j = 0; j < connections[i].profile.collections.length; j++) {

              if (collectionId == connections[i].profile.collections[j]._id) {

                for (var k = 0; k < connections[i].profile.collections[j].files.length; k++) {

                  const file = accountProfiles[i].collections[j].files[k].split('?')[0].split('/')[3];

                  if (handle == file) {

                    connections[i].profile.collections[j].files.splice(k, 1);

                    break;
                  }
                }
              }
            }
          }
        }

        this.dataService.setProperty('connections', connections);
      }

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  user_profile_collection_file_published = async (data: any) => {
    try {

      //console.log('user_profile_collection_file_published', data);

      const { profileId, collectionId, file } = data;

      var selectedProfile = this.dataService.getProperty('selectedProfile');

      if (selectedProfile && selectedProfile._id === profileId) {

        for (var j = 0; j < selectedProfile.collections.length; j++) {

          if (collectionId == selectedProfile.collections[j]._id) {

            selectedProfile.collections[j].files.push(file);
            this.dataService.setProperty('selectedProfile', selectedProfile);
            break;
          }
        }
      }

      var found = false;

      const accountProfiles = this.dataService.getProperty('accountProfiles');

      for (var i = 0; i < accountProfiles.length; i++) {

        if (profileId == accountProfiles[i]._id) {

          for (var j = 0; j < accountProfiles[i].collections.length; j++) {

            if (collectionId == accountProfiles[i].collections[j]._id) {

              accountProfiles[i].collections[j].files.push(file);

              found = true;
              break;
            }
          }

          this.dataService.setProperty('accountProfiles', accountProfiles);
        }
      }

      if (found) {

        const accountProfile = this.dataService.getProperty('accountProfile');

        if (profileId === accountProfile._id) {
          accountProfile.collections[j].files.push(file);
          this.dataService.setProperty('accountProfile', accountProfile);
        }
      }

      if (!found) {

        const connections = this.dataService.getProperty('connections');

        for (var i = 0; i < connections.length; i++) {

          if (profileId == connections[i].profile._id) {

            for (var j = 0; j < connections[i].profile.collections.length; j++) {

              if (collectionId == connections[i].profile.collections[j]._id) {

                connections[i].profile.collections[j].files.push(file);
                break;
              }
            }
          }
        }

        this.dataService.setProperty('connections', connections);
      }

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  user_profile_collection_published = async (data: any) => {
    try {

      //console.log('user_profile_collection_published', data);

      const { profileId, collection } = data;

      var selectedProfile = this.dataService.getProperty('selectedProfile');

      if (selectedProfile && selectedProfile._id === profileId) {

        selectedProfile.collections.unshift(collection);
        this.dataService.setProperty('selectedProfile', selectedProfile);
      }

      var found = false;

      const accountProfiles = this.dataService.getProperty('accountProfiles');

      for (var i = 0; i < accountProfiles.length; i++) {

        if (profileId == accountProfiles[i]._id) {

          accountProfiles[i].collections.unshift(collection);
          found = true;

          break;
        }
      }

      this.dataService.setProperty('accountProfiles', accountProfiles);

      if (found) {

        const accountProfile = this.dataService.getProperty('accountProfile');

        if (profileId === accountProfile._id) {
          accountProfile.collections.unshift(collection);
          this.dataService.setProperty('accountProfile', accountProfile);
        }
      }

      if (!found) {

          const connections = this.dataService.getProperty('connections');

          for (var i = 0; i < connections.length; i++) {

            if (profileId == connections[i].profile._id) {

              connections[i].profile.collections.unshift(collection);
              break;
            }
          }

          this.dataService.setProperty('connections', connections);
        }

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  user_profile_updated = async (data: any) => {
    try {

      //console.log('user_profile_updated', data);

      var found = false;

      const { profileId, profileData } = data;
      const { zipcode, accountId, displayName, about, interests, files } = profileData;

      var accountProfile = this.dataService.getProperty('accountProfile');

      if (profileId === accountProfile._id) {

        found = true;

        const authAccountId = this.dataService.getProperty('authSession').accountId;

        if (authAccountId === accountId) {
          accountProfile.displayName = displayName;
        }

        accountProfile.biography.zipcode = zipcode;
        accountProfile.biography.about = about;
        accountProfile.biography.interests = interests;
        accountProfile.files = files;

        for (var i = 0; i < accountProfile.accounts.length; i++) {
          if (accountProfile.accounts[i]._id === accountId) {
            accountProfile.accounts[i].displayName = displayName;
            break;
          }
        }

        if (found) {
          this.dataService.setProperty('accountProfile', accountProfile);
        }

      }

      var accountProfiles = this.dataService.getProperty('accountProfiles');

      for (var i = 0; i < accountProfiles.length; i++) {

        if (accountProfiles[i]._id === profileId) {

          found = true;

          const authAccountId = this.dataService.getProperty('authSession').accountId;

          if (authAccountId === accountId) {
            accountProfiles[i].displayName = displayName;
          }

          accountProfiles[i].biography.zipcode = zipcode;
          accountProfiles[i].biography.about = about;
          accountProfiles[i].biography.interests = interests;
          accountProfiles[i].files = files;

          for (var j = 0; j < accountProfiles[i].accounts.length; j++) {
            if (accountProfiles[i].accounts[j]._id === accountId) {
              accountProfiles[i].accounts[j].displayName = displayName;
              break;
            }
          }

          break;
        }
      }

      if (found) {
        this.dataService.setProperty('accountProfiles', accountProfiles);
        return;
      }

      if (!found) {

        var connections = this.dataService.getProperty('connections');

        for (var i = 0; i < connections.length; i++) {

          if (profileId === connections[i].profile._id) {

            found = true;

            connections[i].profile.biography.zipcode = zipcode;
            connections[i].profile.biography.about = about;
            connections[i].profile.biography.interests = interests;
            connections[i].profile.files = files;

            for (var j = 0; j < connections[i].profile.accounts.length; j++) {
              if (connections[i].profile.accounts[j]._id === accountId) {
                connections[i].profile.accounts[j].displayName = displayName;
                break;
              }
            }
          }
        }

        if (found) {
          this.dataService.setProperty('connections', connections);
          return;
        }

      }

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  user_primary_profile_updated = async (data: any) => {
    try {

      //console.log('user_primary_profile_updated', data);

      const { profileId } = data;

      var accountProfiles = this.dataService.getProperty('accountProfiles');

      for (var i = 0; i < accountProfiles.length; i++) {
        if (accountProfiles[i]._id == profileId) {
          accountProfiles[i].primary = true;
        } else {
          accountProfiles[i].primary = false;
        }
      }

      this.dataService.setProperty('accountProfiles', accountProfiles);

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  ////////////////////////////////////////////////////////////////////////////////////////////

  user_account_biography_created = async (data: any) => {
    try {

      //console.log('user_account_biography_created', data);

      const { accountBiography } = data;

      this.dataService.setProperty('accountBiography', accountBiography);

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  user_account_biography_updated = async (data: any) => {
    try {

      //console.log('user_account_biography_updated', data);

      const { accountBiography } = data;

      this.dataService.setProperty('accountBiography', accountBiography);

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  ////////////////////////////////////////////////////////////////////////////////////////////

  user_profile_post_comment_reply_updated = async (data: any) => {
    try {

      //console.log('user_profile_post_comment_reply_updated', data);

      const { profileId, postId, commentId, reply } = data;

      var masterFeed = this.dataService.getProperty('masterFeed');

      for (var i = 0; i < masterFeed.length; i++) {
        if (masterFeed[i]._id == postId) {
          for (var j = 0; j < masterFeed[i].comments.length; j++) {
            if (masterFeed[i].comments[j]._id == commentId) {
              for (var k = 0; k < masterFeed[i].comments[j].replies.length; k++) {
                if (masterFeed[i].comments[j].replies[k]._id == reply._id) {
                  masterFeed[i].comments[j].replies[k] = reply;
                  break;
                }
              }
            }
          }
        }
      }

      this.dataService.setProperty('masterFeed', masterFeed);

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  user_profile_post_comment_reply_unpublished = async (data: any) => {
    try {

      //console.log('user_profile_post_comment_reply_unpublished', data);

      const { profileId, postId, commentId, replyId } = data;

      var masterFeed = this.dataService.getProperty('masterFeed');

      for (var i = 0; i < masterFeed.length; i++) {
        if (masterFeed[i]._id == postId) {
          for (var j = 0; j < masterFeed[i].comments.length; j++) {
            if (masterFeed[i].comments[j]._id == commentId) {
              for (var k = 0; k < masterFeed[i].comments[j].replies.length; k++) {
                if (masterFeed[i].comments[j].replies[k]._id == replyId) {
                  masterFeed[i].comments[j].replies.splice(k, 1);
                  break;
                }
              }
            }
          }
        }
      }

      this.dataService.setProperty('masterFeed', masterFeed);

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  user_profile_post_comment_reply_published = async (data: any) => {
    try {

      //console.log('user_profile_post_comment_reply_published', data);

      const { profileId, postId, commentId, reply } = data;

      var masterFeed = this.dataService.getProperty('masterFeed');

      for (var i = 0; i < masterFeed.length; i++) {
        if (masterFeed[i]._id == postId) {
          for (var j = 0; j < masterFeed[i].comments.length; j++) {
            if (masterFeed[i].comments[j]._id == commentId) {
              masterFeed[i].comments[j].replies.push(reply);
              break;
            }
          }
        }
      }

      this.dataService.setProperty('masterFeed', masterFeed);

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  user_profile_post_comment_updated = async (data: any) => {
    try {

      //console.log('user_profile_post_comment_updated', data);

      const { profileId, postId, comment } = data;

      var masterFeed = this.dataService.getProperty('masterFeed');

      for (var i = 0; i < masterFeed.length; i++) {
        if (masterFeed[i]._id == postId) {
          for (var j = 0; j < masterFeed[i].comments.length; j++) {
            if (masterFeed[i].comments[j]._id == comment._id) {
              masterFeed[i].comments[j] = comment;
              break;
            }
          }
        }
      }

      this.dataService.setProperty('masterFeed', masterFeed);

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  user_profile_post_comment_unpublished = async (data: any) => {
    try {

      //console.log('user_profile_post_comment_unpublished', data);

      const { profileId, postId, commentId } = data;

      var masterFeed = this.dataService.getProperty('masterFeed');

      for (var i = 0; i < masterFeed.length; i++) {
        if (masterFeed[i]._id == postId) {
          for (var j = 0; j < masterFeed[i].comments.length; j++) {
            if (masterFeed[i].comments[j]._id == commentId) {
              masterFeed[i].comments.splice(j, 1);
              break;
            }
          }
          break;
        }
      }

      this.dataService.setProperty('masterFeed', masterFeed);

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  user_profile_post_comment_published = async (data: any) => {
    try {

      //console.log('user_profile_post_comment_published', data);

      const { profileId, postId } = data;
      var { comment } = data;

      var masterFeed = this.dataService.getProperty('masterFeed');

      for (var i = 0; i < masterFeed.length; i++) {
        if (masterFeed[i]._id == postId) {
          masterFeed[i].comments.push(comment);
          break;
        }
      }

      this.dataService.setProperty('masterFeed', masterFeed);

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  user_profile_post_updated = async (data: any) => {
    try {

      //console.log('user_profile_post_updated', data);

      var { profileId, post } = data;

      var masterFeed = this.dataService.getProperty('masterFeed');

      for (var i = 0; i < masterFeed.length; i++) {
        if (masterFeed[i]._id == post._id) {

          post.vibes = masterFeed[i].vibes;
          post.vibed = masterFeed[i].vibed;

          masterFeed[i] = post;
          break;
        }
      }

      this.dataService.setProperty('masterFeed', masterFeed);

      var displayFeed = this.dataService.getProperty('displayFeed');

      for (var i = 0; i < displayFeed.length; i++) {
        if (displayFeed[i]._id == post._id) {

          post.vibes = displayFeed[i].vibes;
          post.vibed = displayFeed[i].vibed;

          displayFeed[i] = post;
          break;
        }
      }

      this.dataService.setProperty('displayFeed', displayFeed);

      if (post._id === this.dataService.getProperty('selectedPost')._id) {

        var selectedPost = this.dataService.getProperty('selectedPost');

        post.vibes = selectedPost.vibes;
        post.vibed = selectedPost.vibed;

        this.dataService.setProperty('selectedPost', post);
      }

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  user_profile_post_vibes_updated = async (data: any) => {
    try {

      //console.log('user_profile_post_vibes_updated', data);

      const { profileId, postId, vibes, vibed } = data;

      if (profileId == this.dataService.getProperty('accountProfile')._id) {

        var accountProfile: any = this.dataService.getProperty('accountProfile');
        for (var i = 0; i < accountProfile.posts.length; i++) {

          if (accountProfile.posts[i]._id == postId) {

            accountProfile.posts[i].vibes = vibes;

            if (vibed != null) {
              accountProfile.posts[i].vibed = vibed
            }

            break;
          }
        }
        this.dataService.setProperty('accountProfile', accountProfile);

      } else {

        var connections = this.dataService.getProperty('connections');

        for (var i = 0; i < connections.length; i++) {

          if (profileId == connections[i]._id) {

            for (var j = 0; j < connections[i].posts.length; j++) {

              if (connections[i].posts[j]._id == postId) {

                connections[i].posts[j].vibes = vibes;

                if (vibed != null) {
                  connections[i].posts[j].vibed = vibed;
                }

                break;
              }
            }
          }
        }
        this.dataService.setProperty('connections', connections);
      }

      var masterFeed: any = this.dataService.getProperty('masterFeed');

        for (var i = 0; i < masterFeed.length; i++) {

          if (masterFeed[i]._id == postId) {

            masterFeed[i].vibes = vibes;

            if (vibed != null) {
              masterFeed[i].vibed = vibed;
            }

            break;
          }
        }
        this.dataService.setProperty('masterFeed', masterFeed);

        var displayFeed: any = this.dataService.getProperty('displayFeed');

        for (var i = 0; i < displayFeed.length; i++) {

          if (displayFeed[i]._id == postId) {

            displayFeed[i].vibes = vibes;

            if (vibed != null) {
              displayFeed[i].vibed = vibed;
            }

            break;
          }
        }
        this.dataService.setProperty('displayFeed', displayFeed);

    } catch (error) {
      console.log(error);
      return;
    }
  }

  user_profile_post_unpublished = async (data: any) => {
    try {

      //console.log('user_profile_post_unpublished', data);

      const { profileId, postId } = data;

      const selectedProfile = this.dataService.getProperty('selectedProfile');

      if (selectedProfile && selectedProfile._id === profileId) {
        const selectedProfilePosts = this.dataService.getProperty('selectedProfilePosts');
        for (var i = 0; i < selectedProfilePosts.length; i++) {
          if (selectedProfilePosts[i]._id === postId) {
            selectedProfilePosts.splice(i, 1);
            this.dataService.setProperty('selectedProfilePosts', selectedProfilePosts);
            break;
          }
        }
      }

      var found = false;

      const accountProfiles = this.dataService.getProperty('accountProfiles');

      for (var i = 0; i < accountProfiles.length; i++) {
        if (accountProfiles[i]._id == profileId) {
          for (var j = 0; j < accountProfiles[i].posts.length; j++) {
            if (accountProfiles[i].posts[j]._id == postId) {
              accountProfiles[i].posts.splice(j, 1);
              found = true;
              break;
            }
          }
        }
      }

      if (found) {

        this.dataService.setProperty('accountProfiles', accountProfiles);

        const accountProfile = this.dataService.getProperty('accountProfile');

        if (profileId === accountProfile._id) {
          for (var j = 0; j < accountProfile.posts.length; j++) {
            if (accountProfile.posts[j]._id == postId) {
              accountProfile.posts.splice(j, 1);
              this.dataService.setProperty('accountProfile', accountProfile);
              break;
            }
          }
        }
      }

      if (!found) {

        const connections = this.dataService.getProperty('connections');

        for (var i = 0; i < connections.length; i++) {
          if (connections[i]._id == profileId) {
            for (var j = 0; j < connections[i].posts.length; j++) {
              if (connections[i].posts[j]._id == postId) {
                connections[i].posts.splice(j, 1);
                break;
              }
            }
          }
        }

        this.dataService.setProperty('connections', connections);
      }

      const masterFeed = this.dataService.getProperty('masterFeed');

      for (var i = 0; i < masterFeed.length; i++) {
        if (masterFeed[i]._id == postId) {
          masterFeed.splice(i, 1);
          break;
        }
      }

      this.dataService.setProperty('masterFeed', masterFeed);

      const displayFeed = this.dataService.getProperty('displayFeed');
      var displayFeedIndex = this.dataService.getProperty('displayFeedIndex');

      for (var i = 0; i < displayFeed.length; i++) {
        if (displayFeed[i]._id == postId) {
          displayFeed.splice(i, 1);
          break;
        }
      }

      if (displayFeedIndex > 0) {
        displayFeedIndex -= 1;
      }

      this.dataService.setProperty('displayFeed', displayFeed);
      this.dataService.setProperty('displayFeedIndex', displayFeedIndex);

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  user_profile_post_published = async (data: any) => {
    try {

      //console.log('user_profile_post_published', data);

      const { profileId, post } = data;

      if (this.dataService.getProperty('selectedProfile')) {
        if (this.dataService.getProperty('selectedProfile')._id === profileId) {
          const selectedProfilePosts = this.dataService.getProperty('selectedProfilePosts');
          selectedProfilePosts.unshift(post);
          this.dataService.setProperty('selectedProfilePosts', selectedProfilePosts);
        }
      }

      var found = false;
      var accountProfiles = this.dataService.getProperty('accountProfiles');

      for (var i = 0; i < accountProfiles.length; i++) {
        if (profileId === accountProfiles[i]._id) {
          found = true;
          accountProfiles[i].posts.push(post);
          break;
        }
      }

      this.dataService.setProperty('accountProfiles', accountProfiles);

      if (!found) {

        var connections = this.dataService.getProperty('connections');

        for (var i = 0; i < connections.length; i++) {
          if (profileId === connections[i]._id) {
            connections[i].posts.push(post);
            break;
          }
        }

        this.dataService.setProperty('connections', connections);
      }

      const masterFeed = this.dataService.getProperty('masterFeed');
      masterFeed.push(post);
      this.dataService.setProperty('masterFeed', masterFeed);

      var displayFeedIndex = this.dataService.getProperty('displayFeedIndex');
      displayFeedIndex = displayFeedIndex + 1;

      if (this.dataService.getProperty('displayFeed').length === 0) {
        this.dataService.setProperty('displayFeed', masterFeed);
      }

      if ((this.dataService.getProperty('displayFeed').length !== 0) && this.dataService.getProperty('bufferFeed')) {
        const bufferFeed = this.dataService.getProperty('bufferFeed');
        bufferFeed.push(post);
        this.dataService.setProperty('bufferFeed', bufferFeed);
      }

      if ((this.dataService.getProperty('displayFeed').length !== 0) && !this.dataService.getProperty('bufferFeed')) {
        const bufferFeed = [];
        bufferFeed.push(post);
        this.dataService.setProperty('bufferFeed', bufferFeed);
      }

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  ////////////////////////////////////////////////////////////////////////////////////////////

  user_account_joined_profile = async (data: any) => {
    try {

      //console.log('user_account_joined_profile', data);

      var { profile } = data;

      const accounts = profile.connection.accounts;

      const accountId = this.dataService.getProperty('authSession').accountId;
      var displayName: string;

      for (var i = 0; i < accounts.length; i++) {
        if (accounts[i].account === accountId) {
          displayName = accounts[i].displayName;
          break;
        }
      }

      profile.displayName = displayName;

      const type = profile.type;
      profile.display = this.helperService.processTypeDisplay(accounts, type);

      const accountProfiles = this.dataService.getProperty('accountProfiles') ? this.dataService.getProperty('accountProfiles') : [];

      accountProfiles.push(profile);

      this.dataService.setProperty('accountProfiles', accountProfiles);

      await this.helperService.hideLoading();

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  user_profile_by_joinCode_retrieved = async (data: any) => {
    try {

      //console.log('user_profile_by_joinCode_retrieved', data);

      if (!data) {
        this.dataService.setProperty('joinCodeProfile', 'Profile Not Found');
        return;
      }

      const { profile } = data;

      if (!profile) {
        return;
      }

      this.dataService.setProperty('joinCodeProfile', profile);

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  user_profile_by_tag_retrieved = async (data: any) => {
    try {

      //console.log('user_profile_by_tag_retrieved', data);

      const { tagProfile } = data;

      await this.helperService.hideLoading();

      if (tagProfile === "Profile Not Found") {
        this.dataService.setProperty('tagProfile', 'Profile Not Found');
      }

      if (tagProfile === "Profile Still Pending") {
        this.dataService.setProperty('tagProfile', 'Profile Still Pending');
      }

      if (tagProfile !== "Profile Not Found" && tagProfile !== "Profile Still Pending") {
        this.dataService.setProperty('tagProfile', tagProfile);
      }

      return;

    } catch (error) {
      this.logger.log('ListenerService', 'user_profile_by_tag_retrieved', error, 'error');
      return;
    }
  }


  user_social_connections_retrieved = async (data: any) => {
    try {

      //console.log('user_social_connections_retrieved', data);

      var { connections } = data;

      if (connections.length !== 0) {

        for (var i = 0; i < connections.length; i++) {

          const type = connections[i].type;
          const accounts = connections[i].accounts;
          const display = this.helperService.processTypeDisplay(accounts, type);

          connections[i].display = display;

          for (var j = 0; j < connections[i].posts.length; j++) {

            connections[i].posts[j].thumbnail = connections[i].files[0];
            connections[i].posts[j].profileId = connections[i]._id;
            connections[i].posts[j].online = connections[i].online;
            connections[i].posts[j].tag = connections[i].tag;

            for (var k = 0; k < connections[i].accounts.length; k++) {

              if (connections[i].posts[j].account === connections[i].accounts[k].account) {
                connections[i].posts[j].displayName = connections[i].accounts[k].displayName;
              }
            }

            delete connections[i].posts[j].account;

          }

          await this.fileService.timeBulkSort(connections[i].posts);
        }

        this.dataService.setProperty('connections', connections);

      } else {

        this.dataService.setProperty('connections', []);
      }

      const displayFeed = [];
      var displayFeedIndex: number;
      var length = 0;

      const masterFeed = this.dataService.getProperty('masterFeed');
      length = masterFeed.length;

      if (length === 0) {
        this.dataService.setProperty('displayFeed', []);
      }

      if (length <= 10) {

        for (let i = length - 1; i >= 0; i--) {
          displayFeed.push(masterFeed[i])
          displayFeedIndex = i;
        }

      } else {

        for (var i = length - 1; i >= length - 10; i--) {
          displayFeed.push(masterFeed[i]);
          displayFeedIndex = i;
        }
      }

      this.dataService.setProperty('displayFeed', displayFeed);
      this.dataService.setProperty('displayFeedIndex', displayFeedIndex);

      //await this.helperService.hideLoading();

      // if (!this.dataService.getProperty('tab')) {
      //   this.helperService.goTo('feed');
      // }

      return;

    } catch (error) {
      this.logger.log('ListenerService', 'user_social_connections_retrieved', error, 'error');
      return;
    }
  }

  user_social_connection_published = async (data: any) => {
    try {

      //console.log('user_social_connection_published', data);

      var { connection } = data;

      const type = connection.type;
      const accounts = connection.accounts;
      const display = this.helperService.processTypeDisplay(accounts, type);
      connection.display = display;
      
      for (var j = 0; j < connection.posts.length; j++) {

        connection.posts[j].profileId = connection._id;
        connection.posts[j].tag = connection.tag;

        for (var k = 0; k < connection.accounts.length; k++) {
          if (connection.posts[j].account === connection.accounts[k].account) {
            connection.posts[j].displayName = connection.accounts[k].displayName;
            break;
          }
        }

        delete connection.posts[j].account;

      }

      await this.fileService.timeBulkSort(connection.posts);

      const connections = this.dataService.getProperty('connections');

      connections.push(connection);

      this.dataService.setProperty('connections', connections);

      var queries = this.dataService.getProperty('queries');
      var profiles = queries.profiles;
      var online = profiles.online;
      var offline = profiles.offline;

      for (let i = 0; i < online.length; i++) {
        if (online[i].profileId === connection._id) {
          online.splice(i, 1);
          break;
        }
      }

      for (let i = 0; i < offline.length; i++) {
        if (offline[i].profileId === connection._id) {
          offline.splice(i, 1);
          break;
        }
      }

      profiles.online = online;
      profiles.offline = offline;

      queries.profiles = profiles;

      this.dataService.setProperty('queries', queries);

      this.dataService.toggleInterestsChanged();

      return;

    } catch (error) {
      this.logger.log('ListenerService', 'user_social_connection_published', error, 'error');
      return;
    }
  }

  user_social_connection_updated = async (data: any) => {
    try {

      console.log('user_social_connection_updated', data);

      const { connection } = data;
      const { mute, note } = connection;

      var connections = this.dataService.getProperty('connections');

      for (var i = 0; i < connections.length; i++) {

        if (connections[i]._id === connection._id) {

          if (mute !== null) {
            connections[i].mute = mute;
          }

          if (note !== null) {
            connections[i].note = note;
          }

          break;
        }
      }

      this.dataService.setProperty('connections', connections);

      return;

    } catch (error) {
      this.logger.log('ListenerService', 'user_social_connection_updated', error, 'error');
      return;
    }
  }

  ////////////////////////////////////////////////////////////////////////////////////////////

  user_invite_created = async (data: any) => {
    try {

      //console.log('user_invite_created', data);

      var { invite } = data;

      var invites = this.dataService.getProperty('invites');

      if (!invites) {
        invites = {
          connections: [],
          sent: {
            connections: [],
            signups: []
          }
        };
      }

      if (invite.type === 'connection') {

        const type = invite.requestingProfile.type;
        const accounts = invite.requestingProfile.accounts;
        const display = this.helperService.processTypeDisplay(accounts, type);
        invite.requestingProfile.display = display;

        invites.sent.connections.push(invite);
      }

      this.dataService.setProperty('invites', invites);

      return;

    } catch (error) {
      this.logger.log('ListenerService', 'user_invite_published', error, 'error');
      return;
    }
  }

  user_invite_recieved = async (data: any) => {
    try {

      //console.log('user_invite_recieved', data);

      var { invite } = data;

      var invites = this.dataService.getProperty('invites');

      if (!invites) {
        invites = {
          connections: [],
          sent: []
        };
      }

      if (invite.type === 'connection') {

        const type = invite.requestingProfile.type;
        const accounts = invite.requestingProfile.accounts;
        const display = this.helperService.processTypeDisplay(accounts, type);
        invite.requestingProfile.display = display;

        invites.connections.push(invite);
      }

      this.dataService.setProperty('invites', invites);

      return;

    } catch (error) {
      this.logger.log('ListenerService', 'user_invite_published', error, 'error');
      return;
    }
  }

  user_invites_retrieved = async (data: any) => {
    try {

      //console.log('user_invites_retrieved', data);

      var { invites }: any = data;

      var connections: any = invites.connections;

      var sent = invites.sent;
      var sentConnections = sent.connections;

      for (var i = 0; i < connections.length; i++) {

        const type = connections[i].type;
        const accounts = connections[i].requestingProfile.accounts;
        const display = this.helperService.processTypeDisplay(accounts, type);

        connections[i].requestingProfile.display = display;
      }

      invites.connections = connections;

      for (let i = 0; i < sentConnections.length; i++) {
          
        const type = sentConnections[i].type;
        const accounts = sentConnections[i].accounts;
        const display = this.helperService.processTypeDisplay(accounts, type);
  
        sentConnections[i].display = display;
      }

      sent.connections = sentConnections;

      invites.sent = sent;

      this.dataService.setProperty('invites', invites);

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  user_invite_updated = async (data: any) => {
    try {

      //console.log('user_invite_updated', data);

      var { invite } = data;

      var invites = this.dataService.getProperty('invites');

      for (var i = 0; i < invites.sent.length; i++) {
        if (invites.sent[i]._id == invite._id) {
          invites.sent[i] = invite;
          break;
        }
      }

      this.dataService.setProperty('invites', invites);

      return;

    } catch (error) {
      this.logger.log('ListenerService', 'user_invite_updated', error, 'error');
      return;
    }
  }

  user_invite_unpublished = async (data: any) => {
    try {

      //console.log('user_invite_unpublished', data);

      const { inviteId } = data;

      var invites = this.dataService.getProperty('invites');

      for (var i = 0; i < invites.sent.length; i++) {
        if (invites.sent[i]._id == inviteId) {
          invites.sent.splice(i, 1);
          break;
        }
      }

      this.dataService.setProperty('invites', invites);

      return;

    } catch (error) {
      this.logger.log('ListenerService', 'user_invite_unpublished', error, 'error');
      return;
    }
  }

  ////////////////////////////////////////////////////////////////////////////////////////////

  user_notifications_retrieved = async (data: any) => {
    try {

      //console.log('user_notifications_retrieved', data);

      var { notifications } = data;

      this.dataService.setProperty('notifications', notifications);

      return;

    } catch (error) {
      this.logger.log('ListenerService', 'user_notifications_retrieved', error, 'error');
      return;
    }
  }

  user_notification_deleted = async (data: any) => {
    try {

      //console.log('user_notification_deleted', data);

      const { notificationId } = data;

      var notifications = this.dataService.getProperty('notifications');

      for (var i = 0; i < notifications.length; i++) {
        if (notifications[i]._id == notificationId) {
          notifications.splice(i, 1);
          break;
        }
      }

      this.dataService.setProperty('notifications', notifications);

      return;

    } catch (error) {
      this.logger.log('ListenerService', 'user_notification_deleted', error, 'error');
      return;
    }
  }

  user_notification_created = async (data: any) => {
    try {

      //console.log('user_notification_created', data);

      const { notification } = data;

      var notifications = this.dataService.getProperty('notifications');

      notifications.push(notification);

      this.dataService.setProperty('notifications', notifications);

      return;

    } catch (error) {
      this.logger.log('ListenerService', 'user_notification_created', error, 'error');
      return;
    }
  }

  user_account_biography_required = async (data: any) => {
    try {

      //console.log('user_account_biography_required', data);

      const { accountBiography, sas } = data;

      if (this.dataService.getProperty('firstSession')) {
        return;
      }

      this.dataService.setProperty('firstSession', true);

      if (accountBiography) {
        this.dataService.setProperty('accountBiography', accountBiography);
      }

      var authSession = this.dataService.getProperty('authSession');
      authSession.sas = sas;
      this.dataService.setProperty('authSession', authSession);

      this.dataService.deleteProperty('');

      await this.helperService.hideLoading();

      this.router.navigate(['/onboarding'], { replaceUrl: true });

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  user_account_biography_retrieved = async (data: any) => {
    try {

      //console.log('user_account_biography_retrieved', data);

      const { accountBiography, sas } = data;

      this.dataService.setProperty('accountBiography', accountBiography);

      var authSession = this.dataService.getProperty('authSession');
      authSession.sas = sas;
      this.dataService.setProperty('authSession', authSession);

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  user_profile_session_retrieved = async (data: any) => {
    try {

      //console.log('user_profile_session_retrieved', data);

      var { accountId, profileId, profiles } = data;
      
      for (let i = 0; i < profiles.length; i++) {

        const type = profiles[i].type;
        const accounts = profiles[i].accounts;
        const display = this.helperService.processTypeDisplay(accounts, type);
        profiles[i].display = display;

        var posts = profiles[i].posts;

        for (let j = 0; j < posts.length; j++) {

          posts[j].thumbnail = profiles[i].files[0];
          posts[j].profileId = profiles[i]._id;
          posts[j].tag = profiles[i].tag;

          for (let k = 0; k < accounts.length; k++) {
            if (posts[j].account === accounts[k].account) {
              posts[j].displayName = accounts[k].displayName;
              break;
            }
          }

          //delete posts[j].account;

        }

        if (profiles[i]._id === profileId) {
          this.dataService.setProperty('accountProfile', profiles[i]);
        }
      }

      this.dataService.setProperty('accountProfiles', profiles);

      await this.fileService.timeBulkSort(this.dataService.getProperty('accountProfile').posts);
      await this.postRequestProfileSession();

      await this.helperService.hideLoading();

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  user_profile_deleted = async (data: any) => {
    try {

      //console.log('user_profile_deleted', data);

      const { profileId } = data;

      const accountProfiles = this.dataService.getProperty('accountProfiles') ? this.dataService.getProperty('accountProfiles') : [];

      for (var i = 0; i < accountProfiles.length; i++) {
        if (accountProfiles[i]._id == profileId) {
          accountProfiles.splice(i, 1);
          break;
        }
      }

      this.dataService.setProperty('accountProfiles', accountProfiles);

      if (accountProfiles.length === 0) {
        this.dataService.deleteProperty('accountProfile');
        this.dataService.deleteProperty('masterFeed');
        this.dataService.deleteProperty('displayFeed');
        this.dataService.deleteProperty('invites');
        this.dataService.deleteProperty('conversations');
        this.dataService.deleteProperty('connections');
        this.dataService.deleteProperty('selectedManageProfile');

        this.router.navigate(['/onboarding'], { replaceUrl: true });
      }

      var connections = this.dataService.getProperty('connections') ? this.dataService.getProperty('connections') : [];

      for (var i = 0; i < connections.length; i++) {
        if (connections[i]._id == profileId) {
          connections.splice(i, 1);
          break;
        }
      }

      this.dataService.setProperty('connections', connections);

      var masterFeed = this.dataService.getProperty('masterFeed') ? this.dataService.getProperty('masterFeed') : [];

      for (var i = 0; i < masterFeed.length; i++) {
        if (masterFeed[i].profileId == profileId) {
          masterFeed.splice(i, 1);
          break;
        }
      }

      this.dataService.setProperty('masterFeed', masterFeed);

      var displayFeed = this.dataService.getProperty('displayFeed') ? this.dataService.getProperty('displayFeed') : [];

      for (var i = 0; i < displayFeed.length; i++) {
        if (displayFeed[i].profileId == profileId) {
          displayFeed.splice(i, 1);
          break;
        }
      }

      this.dataService.setProperty('displayFeed', displayFeed);

      var conversations = this.dataService.getProperty('conversations') ? this.dataService.getProperty('conversations') : [];

      for (var i = 0; i < conversations.length; i++) {
        if (conversations[i].profileId == profileId) {
          conversations.splice(i, 1);
          break;
        }
      }

      this.dataService.setProperty('conversations', conversations);

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  user_profile_created = async (data: any) => {
    try {

      //console.log('user_profile_created', data);

      var { profile } = data;

      const accountProfiles = this.dataService.getProperty('accountProfiles') ? this.dataService.getProperty('accountProfiles') : [];

      const type = profile.type;
      const accounts = profile.accounts;
      const display = this.helperService.processTypeDisplay(accounts, type);
      profile.display = display;

      accountProfiles.push(profile);
      this.dataService.setProperty('accountProfiles', accountProfiles);

      await this.helperService.hideLoading();

      return;

    } catch (error) {
      console.log(error);
      return;
    }

  }

  user_account_disabled = async (data: any) => {
    try {

      //console.log('user_account_disabled', data);

      await this.socketService.emit('user_profile_session_ended');

      this.socketService.disconnect();

      this.dataService.deleteProfileSocket();
      this.dataService.deleteProperties();

      this.router.navigate(['/login'], { replaceUrl: true });

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  async preLoadListeners() {
    try {

      await this.socketService.removeAllListeners();

      this.socketService.addListener('user_account_biography_required', this.user_account_biography_required);
      this.socketService.addListener('user_account_biography_retrieved', this.user_account_biography_retrieved);
      this.socketService.addListener('user_profile_session_retrieved', this.user_profile_session_retrieved);

      if (this.dataService.getProperty('authSession').admin) {
        await this.loadAdminListeners();
      } else {
        await this.loadUserListeners();
      }

      return;

    } catch (error) {
      this.logger.log('ListenerService', 'preLoadListeners', error, 'error');
      return;
    }
  }

  async loadAdminListeners() {
    try {

      this.socketService.addListener('admin_system_settings_retrieved', this.listeners.settings.retrieved);
      this.socketService.addListener('admin_system_setting_updated', this.listeners.settings.updated);

      this.socketService.addListener('biography_updated', this.listeners.account.biography_updated);
      this.socketService.addListener('admin_account_enabled', this.listeners.account.enabled);
      this.socketService.addListener('admin_account_disabled', this.listeners.account.disabled);
      this.socketService.addListener('admin_accounts_retrieved', this.listeners.account.retrieved);
      this.socketService.addListener('admin_account_deleted', this.listeners.account.deleted);
      this.socketService.addListener('admin_account_updated', this.listeners.account.updated);
      this.socketService.addListener('admin_account_created', this.listeners.account.created);

      this.socketService.addListener('admin_profile_enabled', this.listeners.profile.enabled);
      this.socketService.addListener('admin_profile_disabled', this.listeners.profile.disabled);
      this.socketService.addListener('admin_profiles_retrieved', this.listeners.profile.retrieved);
      this.socketService.addListener('admin_profile_deleted', this.listeners.profile.deleted);
      this.socketService.addListener('admin_profile_updated', this.listeners.profile.updated);
      this.socketService.addListener('admin_profile_created', this.listeners.profile.created);

      this.socketService.addListener('admin_account_tickets_retrieved', this.listeners.ticket.retrieved);
      this.socketService.addListener('admin_account_ticket_closed', this.listeners.ticket.closed);
      this.socketService.addListener('admin_account_ticket_updated', this.listeners.ticket.updated);
      this.socketService.addListener('admin_account_ticket_created', this.listeners.ticket.created);
      this.socketService.addListener('admin_account_ticket_deleted', this.listeners.ticket.deleted);
      this.socketService.addListener('admin_account_ticket_reopened', this.listeners.ticket.reopened);

      this.socketService.addListener('admin_account_verification_deleted', this.admin_account_verification_deleted);
      this.socketService.addListener('admin_account_verifications_retrieved', this.admin_account_verifications_retrieved);
      this.socketService.addListener('admin_account_verification_created', this.admin_account_verification_created);
      this.socketService.addListener('admin_account_verification_processed', this.admin_account_verification_processed);

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  async loadUserListeners() {
    try {

      this.socketService.addListener('user_profile_zipcode_retrieved', this.user_profile_zipcode_retrieved);
      this.socketService.addListener('user_account_profile_required', this.user_account_profile_required);

      this.socketService.addListener('user_profile_query_offline', this.user_profile_query_offline);
      this.socketService.addListener('user_profile_query_online', this.user_profile_query_online);
      this.socketService.addListener('user_profile_connection_offline', this.user_profile_connection_offline);
      this.socketService.addListener('user_profile_connection_online', this.user_profile_connection_online);

      this.socketService.addListener('user_profile_blocked', this.user_profile_blocked);

      this.socketService.addListener('user_system_settings_retrieved', this.listeners.settings.retrieved);
      this.socketService.addListener('user_system_setting_updated', this.listeners.settings.updated);

      this.socketService.addListener('user_account_verification_deleted', this.user_account_verification_deleted);
      this.socketService.addListener('user_account_verification_updated', this.user_account_verification_updated);
      this.socketService.addListener('user_account_verification_retrieved', this.user_account_verification_retrieved);
      this.socketService.addListener('user_account_biography_created', this.user_account_biography_created);
      this.socketService.addListener('user_profile_flirt_received', this.user_profile_flirt_received);
      this.socketService.addListener('user_profile_deleted', this.user_profile_deleted);
      this.socketService.addListener('user_profile_invite_sent', this.user_profile_invite_sent);
      this.socketService.addListener('user_account_joined_profile', this.user_account_joined_profile);
      this.socketService.addListener('user_profile_by_joinCode_retrieved', this.user_profile_by_joinCode_retrieved);
      this.socketService.addListener('user_profile_session_updated', this.user_profile_session_updated);

      this.socketService.addListener('user_profile_tag_check_retrieved', this.user_profile_tag_check_retrieved);
      this.socketService.addListener('user_profile_query_viewed', this.user_profile_query_viewed);
      this.socketService.addListener('user_profile_query_post_updated', this.user_profile_query_post_updated);
      this.socketService.addListener('user_profile_query_post_unpublished', this.user_profile_query_post_unpublished);
      this.socketService.addListener('user_profile_query_post_published', this.user_profile_query_post_published);
      this.socketService.addListener('user_profile_query_blocked', this.user_profile_query_blocked);
      this.socketService.addListener('user_profile_query_flirted', this.user_profile_query_flirted);
      this.socketService.addListener('query_file_unpublished', this.query_file_unpublished);
      this.socketService.addListener('query_file_published', this.query_file_published);
      this.socketService.addListener('user_profile_query_file_unpublished', this.user_profile_query_file_unpublished);
      this.socketService.addListener('user_profile_query_file_published', this.user_profile_query_file_published);
      this.socketService.addListener('user_profile_queries_retrieved', this.user_profile_queries_retrieved);
      this.socketService.addListener('user_profile_query_updated', this.user_profile_query_updated);
      this.socketService.addListener('user_profile_query_published', this.user_profile_query_published);
      this.socketService.addListener('user_profile_query_unpublished', this.user_profile_query_unpublished);

      this.socketService.addListener('user_conversation_published', this.user_conversation_published);
      this.socketService.addListener('user_conversation_updated', this.user_conversation_updated);
      this.socketService.addListener('user_conversation_unpublished', this.user_conversation_unpublished);
      this.socketService.addListener('user_conversations_retrieved', this.user_conversations_retrieved);
      this.socketService.addListener('user_conversation_message_published', this.user_conversation_message_published);
      this.socketService.addListener('user_conversation_message_unpublished', this.user_conversation_message_unpublished);
      this.socketService.addListener('user_conversation_message_updated', this.user_conversation_message_updated);

      this.socketService.addListener('user_ticket_updated', this.user_ticket_updated);
      this.socketService.addListener('user_ticket_unpublished', this.user_ticket_unpublished);
      this.socketService.addListener('user_ticket_published', this.user_ticket_published);
      this.socketService.addListener('user_tickets_retrieved', this.user_tickets_retrieved);

      this.socketService.addListener('user_account_biography_updated', this.user_account_biography_updated);
      this.socketService.addListener('user_profile_updated', this.user_profile_updated);

      this.socketService.addListener('user_profile_post_published', this.user_profile_post_published);
      this.socketService.addListener('user_profile_post_unpublished', this.user_profile_post_unpublished);
      this.socketService.addListener('user_profile_post_updated', this.user_profile_post_updated);
      this.socketService.addListener('user_profile_post_vibes_updated', this.user_profile_post_vibes_updated);
      this.socketService.addListener('user_profile_post_comment_published', this.user_profile_post_comment_published);
      this.socketService.addListener('user_profile_post_comment_unpublished', this.user_profile_post_comment_unpublished);
      this.socketService.addListener('user_profile_post_comment_updated', this.user_profile_post_comment_updated);
      this.socketService.addListener('user_profile_post_comment_reply_published', this.user_profile_post_comment_reply_published);
      this.socketService.addListener('user_profile_post_comment_reply_unpublished', this.user_profile_post_comment_reply_unpublished);
      this.socketService.addListener('user_profile_post_comment_reply_updated', this.user_profile_post_comment_reply_updated);

      this.socketService.addListener('user_profile_by_tag_retrieved', this.user_profile_by_tag_retrieved);
      this.socketService.addListener('user_profile_collection_published', this.user_profile_collection_published);
      this.socketService.addListener('user_profile_collection_unpublished', this.user_profile_collection_unpublished);
      this.socketService.addListener('user_profile_collection_file_published', this.user_profile_collection_file_published);
      this.socketService.addListener('user_profile_collection_file_unpublished', this.user_profile_collection_file_unpublished);
      this.socketService.addListener('user_profile_collection_updated', this.user_profile_collection_updated);
      this.socketService.addListener('user_social_connection_published', this.user_social_connection_published);
      this.socketService.addListener('user_social_connections_retrieved', this.user_social_connections_retrieved);
      this.socketService.addListener('user_social_connection_updated', this.user_social_connection_updated);
      this.socketService.addListener('user_social_connection_unpublished', this.user_social_connection_unpublished);

      this.socketService.addListener('user_connection_invite_published', this.user_connection_invite_published);
      this.socketService.addListener('user_invite_created', this.user_invite_created);
      this.socketService.addListener('user_invite_recieved', this.user_invite_recieved);
      this.socketService.addListener('user_invites_retrieved', this.user_invites_retrieved);
      this.socketService.addListener('user_invite_updated', this.user_invite_updated);
      this.socketService.addListener('user_invite_unpublished', this.user_invite_unpublished);

      this.socketService.addListener('user_notification_created', this.user_notification_created);
      this.socketService.addListener('user_notification_deleted', this.user_notification_deleted);
      this.socketService.addListener('user_notifications_retrieved', this.user_notifications_retrieved);

      this.socketService.addListener('user_profile_created', this.user_profile_created);
      this.socketService.addListener('user_account_disabled', this.user_account_disabled);

      return;

    } catch (error) {
      this.logger.log('ListenerService', 'loadListeners', error, 'error');
      return;
    }
  }

  async postRequestProfileSession() {
    try {

      const accountId = this.dataService.getProperty('authSession').accountId;
      const profileId = this.dataService.getProperty('accountProfile')._id;

      this.socketService.emit('user_social_connections_requested', { profileId: profileId });
      this.socketService.emit('user_invites_requested', { profileId: profileId });
      this.socketService.emit('user_notifications_requested', { accountId: accountId });
      this.socketService.emit('user_tickets_requested');
      this.socketService.emit('user_profile_queries_requested');
      this.socketService.emit('user_conversations_requested');

      await this.helperService.hideLoading();
      this.helperService.goTo('feed');

      return;

    } catch (error) {
      this.logger.log('ListenerService', 'postRequestProfileSession', error, 'error');
      return;
    }
  }

}
