
export class Settings {

    constructor(private dataService: any, private helperService: any) {}
  
    retrieved = async (data: any) => {
      try {

        //console.log('admin_settings_retrieved', data);
    
        const { setting } = data;
  
        this.dataService.setProperty('settings', setting);
    
        return;
    
      } catch (error) {
        console.log(error);
        return;
      }
    }

    updated = async (data: any) => {
      try {

        //console.log('admin_settings_updated', data);
    
        const { setting } = data;

        this.dataService.setProperty('settings', setting);

        this.dataService.setProperty('fields', []);
        this.dataService.setProperty('options', []);

        const forms = this.dataService.getProperty('settings').forms;

        for (var i = 0; i < forms.length; i++) {
          if (forms[i].name === this.dataService.getProperty('selectedForm')) {
            if (forms[i].fields.length !== 0) {
              this.dataService.setProperty('fields', forms[i].fields);
              for (var j = 0; j < forms[i].fields.length; j++) {
                console.log(forms[i].fields[j].name);
                if (forms[i].fields[j].name === this.dataService.getProperty('selectedField')) {
                  if (forms[i].fields[j].options.length !== 0) {
                    this.dataService.setProperty('options', forms[i].fields[j].options);
                  }
                }
              }
            }
          }
        }
    
        return;
    
      } catch (error) {
        console.log(error);
        return;
      }
    }
  
  }