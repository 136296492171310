
import { Injectable } from '@angular/core';
import { Router } from "@angular/router";

import { ApiService } from './shared/api.service';
import { DataService } from './shared/data.service';
import { HelperService } from './shared/helper.service';
import { LoggerService } from './shared/logger.service';
import { JwtService } from './shared/jwt.service';
import { LocalStorageService } from './shared/local-storage.service';
import { SocketService } from './shared/socket.service';


@Injectable()


export class AuthService  {

  firstSession = false;

  sessionError;

  jwt;

  currentPath;
  selectedAccountProfile;
  accountDetail;
  selectedCategory;

  newAccountDetails;
  newAccountBiography;


  constructor(private apiService: ApiService, private dataService: DataService, private helperService: HelperService, private logger: LoggerService, private router: Router, private jwtService: JwtService, private localStorageService: LocalStorageService, private socketService: SocketService) {}

  async checkAuthSession(): Promise<boolean> {
    try {

      if (!this.dataService.getProperty('authSession')) {
        return false;
      }

      const expired = this.jwtService.expired(this.dataService.getProperty('authSession').authJwt);

      if (expired) {

        const sessionPatched = await this.patchAuthSession();

        if (!sessionPatched) {
          return false;
        }

      }

      return true;

    } catch (error) {
      this.logger.log('AuthService', 'checkSession', error, 'error');
      return;
    }
  }

  async createAuthSession(email: string, password: string) {
    try {

      const url = '/auth/session/';
      const body = {
        email: email,
        password: password
      };

      var response = await this.apiService.postApi(url, body);

      if (!response) {
        return;
      }

      response = JSON.parse(response);

      if (response.result) {
        return response.result;
      }

      const { accountId, admin, sessionId, authJwt } = response;

      this.dataService.setProperty('authSession', { accountId, admin, sessionId, authJwt });

      return 'Success';

    } catch (error) {
      console.log(error);
      return;
    }
  }

  async patchAuthSession(): Promise<boolean> {
    try {

      const { accountId, admin, sessionId, authJwt } = this.dataService.getProperty('authSession');

      const url = '/auth/session/';
      const body = {};

      const response = JSON.parse(await this.apiService.patchApi(url, body));

      const { newAuthJwt } = response;

      if (newAuthJwt) {
        this.dataService.setProperty('authSession', { accountId, admin, sessionId, newAuthJwt });
        return true;
      }

      return false;

    } catch (error) {
      this.logger.log('AuthService', 'patchSession', error, 'error');
      return;
    }
  }

}
