
export const environment = {

    api: {
      url: 'https://apidev.openvibes.com:',
      port: 443
    },
  
    ws: {
      url: 'https://apidev.openvibes.com:',
      port: 443
    },
  
    log: {
      level: 'error', // debug, info, warn, error
    },
  
    production: true
  
  };