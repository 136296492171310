
import { Injectable } from '@angular/core';


@Injectable({ providedIn: 'root' })


export class LoggerService {

  logLevelSetting: string = 'error';

  timeStamp = new Date().toISOString();

  constructor() {}

  logLevelValue(logLevel: string): number {
    try {

      var logLevelValue: number;

      switch (logLevel) {

        case 'debug':
          logLevelValue = 0;
          break;

        case 'info':
          logLevelValue = 1;
          break;

        case 'warn':
          logLevelValue = 2;
          break;

        case 'error':
          logLevelValue = 3;
          break;

      }

      return logLevelValue;

    } catch (error) {
      console.log('[error] : [LoggerService] : [logLevelValue] : ' + error + ' : ' + this.timeStamp);
      return;
    }
  }

  logLevelCheck(logLevel: string): boolean {
    try {

      const logLevelValue = this.logLevelValue(logLevel);
      const logLevelSettingValue = this.logLevelValue(this.logLevelSetting);

      if (logLevelValue >= logLevelSettingValue) return true;

      return false;

    } catch (error) {
      console.log('[error] : [LoggerService] : [logLevelCheck] : ' + error + ' : ' + this.timeStamp);
      return;
    }
  }

  async log(sourceClass: string, sourceMethod: string, message: any, logLevel: string): Promise<void> {
    try {

      if (this.logLevelCheck(logLevel)) {
        const logData = '[' + logLevel + '] : [' + sourceClass + '] : [' + sourceMethod + '] : ' + message + ' : ' + this.timeStamp;
        console.log(logData);
      }

      return;

    } catch (error) {
        console.log('[error] : [LoggerService] : [log] : ' + error + ' : ' + this.timeStamp);
        return;
    }
  }

}
