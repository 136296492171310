
import { Injectable } from '@angular/core';

import { LoggerService } from './logger.service';


@Injectable({ providedIn: 'root' })


export class LocalStorageService {

  constructor(private logger: LoggerService) {}

  clear(): void {
    try {

      localStorage.clear();
      this.logger.log('LocalStorageService', 'clear', 'localStorage cleared', 'debug');

      return;

    } catch (error) {
      this.logger.log('LocalStorageService', 'clear', error, 'error');
      return;
    }
  }

  getItem(key: string): string {
    try {

      const value =  localStorage.getItem(key);
      this.logger.log('LocalStorageService', 'getItem', value, 'debug');

      return value;

    } catch (error) {
      this.logger.log('LocalStorageService', 'getItem', error, 'error');
      return;
    }
  }

  removeItem(key: string): void {
    try {

      localStorage.removeItem(key);
      this.logger.log('LocalStorageService', 'removeItem', 'localStorage item removed', 'debug');

      return;

    } catch (error) {
      this.logger.log('LocalStorageService', 'removeItem', error, 'error');
      return;
    }
  }

  setItem(key: string, value: string): void {
    try {

      localStorage.setItem(key, value);
      this.logger.log('LocalStorageService', 'setItem', key + ' : ' + value, 'debug');

    } catch (error) {
      this.logger.log('LocalStorageService', 'setItem', error, 'error');
      return;
    }
  }

  getAllItems() {
    try {

      const items = {};
      for (let i = 0; i < localStorage.length; i++) {
        items[localStorage.key(i)] = localStorage.getItem(localStorage.key(i));
      }

      return items;

    } catch (error) {
      this.logger.log('LocalStorageService', 'getAllItems', error, 'error');
      return;
    }
  }

}
