import { Injectable } from '@angular/core';

import { DataService } from '../data.service';
import { HelperService } from '../helper.service';

import { Account } from './admin/account.listeners';
import { Profile } from './admin/profile.listeners';
import { Settings } from './admin/settings.listeners';
import { Ticket } from './admin/ticket.listeners';

@Injectable({ providedIn: 'root' })

export class listeners {

    constructor(private dataService: DataService, private helperService: HelperService) {}

    account = new Account(this.dataService, this.helperService);
    profile = new Profile(this.dataService, this.helperService);
    settings = new Settings(this.dataService, this.helperService);
    ticket = new Ticket(this.dataService, this.helperService);
    
  
}