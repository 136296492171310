
import { Injectable, inject } from '@angular/core';

import { ApiService } from './shared/api.service';
import { DataService } from './shared/data.service';
import { FileService } from './shared/file.service';
import { HelperService } from './shared/helper.service';
import { ListenerService } from './shared/listener.service';
import { LoggerService } from './shared/logger.service';
import { SocketService } from './shared/socket.service';


@Injectable({ providedIn: 'root' })


export class UserService {

  constructor(private fileService: FileService, private dataService: DataService, private listenerService: ListenerService, private logger: LoggerService, private socketService: SocketService) {}

  async connectProfileSocket() {
    try {

      await this.socketService.create();
      await this.socketService.connect();

      const listeners = await this.socketService.checkListeners();

      if (listeners === undefined || listeners === null) {
        await this.listenerService.preLoadListeners();
      }

      return;

    } catch (error) {
      this.logger.log('UserService', 'connectProfileSocket', error, 'error');
      return;
    }
  }

  async requestAdminSession() {
    try {

      this.socketService.emit('admin_system_settings_requested');
      this.socketService.emit('admin_accounts_requested');
      this.socketService.emit('admin_profiles_requested');
      this.socketService.emit('admin_account_tickets_requested');
      this.socketService.emit('admin_account_verifications_requested');

      return;

    } catch (error) {
      this.logger.log('UserService', 'requestAdminSession', error, 'error');
      return;
    }
  }

  async requestProfileSession() {
    try {

      this.socketService.emit('user_profile_session_requested');

      return;

    } catch (error) {
      this.logger.log('UserService', 'requestProfileSession', error, 'error');
      return;
    }
  }

  async updatePrimaryProfile() {
    try {

      if(!this.dataService.getProperty('selectedManageProfile')) {
        return;
      }

      const profileId = this.dataService.getProperty('selectedManageProfile')._id;

      this.socketService.emit('user_primary_profile_updated', { profileId });

      var accountProfiles: any = this.dataService.getProperty('accountProfiles');

      if (!accountProfiles) {
        return;
      }

      for (var i = 0; i < accountProfiles.length; i++) {

        if (accountProfiles[i]._id == profileId) {
          accountProfiles[i].primary = true;
        } else {
          accountProfiles[i].primary = false;
        }
      }

      this.dataService.setProperty('accountProfiles', accountProfiles);

      return;

    } catch (error) {
      this.logger.log('UserService', 'updatePrimaryProfile', error, 'error');
      return;
    }
  }

  async createProfile(type: string, tag: string, zipcode: string, responses: any, displayName: string, joinCode: string) { // MOVE THIS TO THE PAGE LOGIC INSTEAD OF A COMMON USER SERVICE?
    try {

      var profileData: any = {
        type: type,
        tag: tag,
        zipcode: zipcode,
        responses: responses,
        displayName: displayName
      };

      if (type === 'Shared') {
        profileData.joinCode = joinCode;
      }

      this.socketService.emit('user_profile_requested', profileData);

      return;

    } catch (error) {
      this.logger.log('UserService', 'createProfile', error, 'error');
      return;
    }
  }

  async updateProfile(zipcode?: string, responses?: any, displayName?: string) {
    try {

      const profileId = this.dataService.getProperty('selectedManageProfile')._id;

      var profileData: any = {
        profileId: profileId,
      };

      if (zipcode) {
        profileData.zipcode = zipcode;
      }

      if (responses) {
        profileData.responses = responses;
      }

      if (displayName) {
        profileData.displayName = displayName;
      }

      this.socketService.emit('user_profile_updated', profileData);

      var accountProfiles: any = this.dataService.getProperty('accountProfiles');

      for (var i = 0; i < accountProfiles.length; i++) {

        if (accountProfiles[i]._id == profileId) {

          if (zipcode) {
            accountProfiles[i].biography.zipcode = zipcode;
          }

          if (displayName) {
            for (var j = 0; j < accountProfiles[i].connection.accounts.length; j++) {
              if (accountProfiles[i].connection.accounts[j].account == this.dataService.getProperty('authSession').accountId) {
                accountProfiles[i].displayName = displayName;
                accountProfiles[i].connection.accounts[j].displayName = displayName;
                break;
              }
            }
          }

        }
      }

      this.dataService.setProperty('accountProfiles', accountProfiles);

      return;

    } catch (error) {
      this.logger.log('UserService', 'updateProfile', error, 'error');
      return;
    }
  }

  async deleteProfile() {
    try {

      const profileId = this.dataService.getProperty('selectedManageProfile')._id;

      this.socketService.emit('user_profile_deleted', { profileId });

      return;

    } catch (error) {
      this.logger.log('UserService', 'deleteProfile', error, 'error');
      return;
    }
  }

  async deleteSocialConnection() {
    try {

      const connectionId = this.dataService.getProperty('selectedConnection')._id;

      this.socketService.emit('user_social_connection_deleted', { connectionId });

      return;

    } catch (error) {
      this.logger.log('UserService', 'deleteSocialConnection', error, 'error');
      return;
    }
  }

  async updateSocialConnectionProfile(familiarity?: string, mute?: boolean, note?: string) {
    try {

      const connectionId = this.dataService.getProperty('selectedConnection')._id;

      var connectionData: any = {
        connectionId: connectionId,
        familiarity: familiarity,
        mute: mute,
        note: note
      };

      this.socketService.emit('user_social_connection_modified', connectionData);

      return;

    } catch (error) {
      this.logger.log('UserService', 'updateSocialConnectionProfile', error, 'error');
      return;
    }
  }

  async createInvite(email: string, familiarity: string, secret: boolean, message?: string) {
    try {

      var inviteData: any = {
        email: email,
        familiarity: familiarity,
        secret: secret
      };

      if (message) {
        inviteData.message = message;
      }

      this.socketService.emit('user_invite_created', inviteData);

      return;

    } catch (error) {
      this.logger.log('UserService', 'createInvite', error.error, 'error');
      return;
    }
  }

  async processInvite(accepted: boolean, displayName?: string, familiarity?: string) {
    try {

      const invite = this.dataService.getProperty('selectedInvite');
      const inviteId = invite._id;
      const type = invite.type;

      var inviteData: any = {
        inviteId: inviteId,
        accepted: accepted,
      };

      if (displayName !== null) {
        inviteData.displayName = displayName;
      }

      if (familiarity !== null) {
        inviteData.familiarity = familiarity;
      }

      this.socketService.emit('user_invite_processed', inviteData);

      if (type === 'connection') {

        var invites = this.dataService.getProperty('invites');

        for (var i = 0; i < invites.connections.length; i++) {
          if (invites.connections[i]._id == inviteId) {
            invites.connections.splice(i, 1);
            break;
          }
        }

        this.dataService.setProperty('invites', invites);
      }

      return;

    } catch (error) {
      this.logger.log('UserService', 'processInvite', error, 'error');
      return;
    }
  }

  deleteInvite(invite: any) {
    try {

      this.socketService.emit('user_invite_deleted', { invite });

      return;

    } catch (error) {
      this.logger.log('UserService', 'removeInvite', error, 'error');
      return;
    }
  }

}
